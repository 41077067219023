import { Theme } from './theme';
import { mqFactory } from './mediaQueries';

/**
 * Default theme settings for all sites, used by Emotion’s `ThemeProvider`.
 * Can be overridden by the corresponding `styles/themes/theme.ts` file per site.
 */
const defaultBreakpoints = [
  {
    name: 'sm',
    width: 576,
  },
  {
    name: 'md',
    width: 768,
  },
  {
    name: 'lg',
    width: 992,
  },
  {
    name: 'xl',
    width: 1280,
  },
  {
    name: 'xxl',
    width: 1920,
  },
];

const theme: Theme = {
  color: {
    // brand colours
    brand: '#00977d', // green
    brand80: '#33ac97',
    primaryHighlight: '#6ec2b7',
    secondary: '#ff4b19',
    secondaryHighlight: '#e4a5a3',

    // neutral colours
    black: '#000',
    text: '#222',
    grey01: '#5a5a5a',
    grey02: '#909090',
    grey03: '#c8c8c8',
    grey04: '#e8e8e8',
    grey05: '#f3f3f3',
    white: '#fff',

    // notification colours
    info: '#3694ba',
    info20: '#d7eaf1',
    error: '#d63f24',
    error20: '#f7d9d3',
    success: '#75b21b',
    success20: '#e3f0d1',
  },

  gradient: {
    green: { from: '#0D8C76', to: '#06705F' },
    orange: { from: '#FF4B19', to: '#FF8664' },
    mint: { from: '#6EC2B7', to: '#4FBBAD' },
    brand: { from: '#FF4B19', via: '#E4A5A3', to: '#6CC2B6' },

    natural: { from: '#B6BFC1', to: '#fff' },
    warning: { from: '#FFE8EB', to: '#fff' },
    naturalLight: { from: '#f5f5f5', to: '#fff' },
  },

  fontFamily: {
    default: "'Source Sans Pro', ui-sans-serif, system-ui, sans-serif",
    alternate:
      "Quicksand, Bryant, 'Proxima Nova Soft', Arial Rounded MT Bold, sans-serif",
  },
  fontWeight: {
    body: 'normal',
    heading: 'bold',
  },
  fontSize: {
    xl: {
      size: '2.666rem',
      lineHeight: '1.4',
    },
    lg: {
      size: '2.111rem',
      lineHeight: '1.333',
    },
    md: {
      size: '1.555rem',
      lineHeight: '1.3',
    },
    sm: {
      size: '1rem',
      lineHeight: '1.25',
    },
    xs: {
      size: '0.888rem',
      lineHeight: '1.2',
    },
    xxs: {
      size: '0.833rem',
      lineHeight: '1',
    },
  },
  baseSize: '18px',
  spacing: {
    0: '0',
    5: '0.125rem',
    10: '0.25rem',
    20: '0.5rem',
    30: '0.75rem',
    40: '1rem',
    50: '1.5rem',
    60: '2rem',
    70: '2.5rem',
    80: '3rem',
    90: '4rem',
    100: '4.5rem',
    110: '6rem',
    120: '7rem',
    130: '8rem',
    140: '9rem',
    150: '10rem',
  },
  borderRadius: {
    none: '0',
    xxs: '0.125rem',
    xs: '0.25rem',
    sm: '0.5rem',
    md: '0.75rem',
    lg: '1.5rem',
  },
  boxShadow: {
    sm: '0 0.375rem 1.25rem rgb(0 0 0 / 6%)',
    md: '0 0.625rem 1.5rem rgb(0 0 0 / 8%)',
    lg: '0 0.625rem 2rem rgb(0 0 0 / 14%)',
  },
  backgroundImage: {
    // @see https://www.scien.cx/2021/11/10/adding-radial-gradients-to-tailwind-css/
    none: 'none',
    'gradient-to-t': 'linear-gradient(0deg, var(--tw-gradient-stops))',
    'gradient-to-b': 'linear-gradient(180deg, var(--tw-gradient-stops))',
    'gradient-to-r': 'linear-gradient(90deg, var(--tw-gradient-stops))',
    'gradient-to-l': 'linear-gradient(270deg, var(--tw-gradient-stops))',
    'gradient-radial':
      'radial-gradient(ellipse at center, var(--tw-gradient-stops))',
    'gradient-radial-at-t':
      'radial-gradient(ellipse at top, var(--tw-gradient-stops))',
    'gradient-radial-at-b':
      'radial-gradient(ellipse at bottom, var(--tw-gradient-stops))',
    'gradient-radial-at-l':
      'radial-gradient(ellipse at left, var(--tw-gradient-stops))',
    'gradient-radial-at-r':
      'radial-gradient(ellipse at right, var(--tw-gradient-stops))',
    'gradient-radial-at-tl':
      'radial-gradient(ellipse at top left, var(--tw-gradient-stops))',
    'gradient-radial-at-tr':
      'radial-gradient(ellipse at top right, var(--tw-gradient-stops))',
    'gradient-radial-at-bl':
      'radial-gradient(ellipse at bottom left, var(--tw-gradient-stops))',
    'gradient-radial-at-br':
      'radial-gradient(ellipse at bottom right, var(--tw-gradient-stops))',
  },
  mq: mqFactory(defaultBreakpoints),
};

export { theme };
