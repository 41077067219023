import React, { useEffect, useRef, useState, FocusEvent } from 'react';
import { StationMarker } from '@engineering/icons';
import {
  SearchSuggestion,
  Runtime,
  useSearchSuggestions,
  getLocation,
} from '@silvertours/front-entities';
import { I18nLegacy } from '@silvertours/front-legacy-shared';
import { useTranslations } from 'next-intl';
// import { SearchHistory } from '@silvertours/front-features/src/SearchFormLegacy';
import { Container, StyledInput } from './SelectStation.styles';
import { useSearchContext } from '../../SearchFormLegacy/context';
import { Suggestion } from './Suggestion';
import { RentalDirection } from '../../SearchFormLegacy/context/types';

type CommonSearchInputProps = {
  direction: RentalDirection;
  id: string;
  name?: string;
  onSuggestionSelect?: (suggestion: SearchSuggestion) => void;
};

type DepartureProps = {
  direction: 'departure';
  onServiceNumbersSubmit: () => void;
} & CommonSearchInputProps;

type DestinationProps = {
  direction: 'destination';
} & CommonSearchInputProps;

type SearchInputProps = DepartureProps | DestinationProps;

const isDeparture = (props: SearchInputProps): props is DepartureProps =>
  (props as DepartureProps).direction === 'departure';

const getSubmit = (props: SearchInputProps) =>
  isDeparture(props) ? props.onServiceNumbersSubmit : undefined;

export const SelectStation = (props: SearchInputProps) => {
  const {
    getInputValue,
    isInternalCall,
    state,
    setInputValue,
    makeSetLocation,
    setError,
  } = useSearchContext();

  const { direction, id, name, onSuggestionSelect } = props;
  const handleSubmit = getSubmit(props);
  const setLocation = makeSetLocation(direction);
  const initValue = getInputValue(direction);
  const [value, setValue] = useState(initValue);
  const shouldSearchServiceNumbers =
    isInternalCall && direction === 'departure' && value[0] === '#';

  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [showLocationSuggest, setShowLocationSuggest] = useState(false);
  const fieldRef = useRef<HTMLInputElement>(null);
  const t = useTranslations('features.stage');
  const { language, market } = I18nLegacy.useLocale();
  const { consumerApiSettings } = Runtime.useConsumerApi();

  const { clearResults, fetchSuggestions, loading, results } =
    useSearchSuggestions(language, market, consumerApiSettings);

  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.focus();
    }
  });

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setError({ kind: 'locationError', message: '' });

    if (event.target.value === '') {
      setShowSearchHistory(true);
    } else {
      fetchSuggestions(event.target.value);
      setShowLocationSuggest(true);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!event.relatedTarget?.className.includes('searchHistory')) {
      setShowSearchHistory(false);
    }

    if (
      !(
        event.relatedTarget?.className.includes('inputCloseIcon') ||
        event.relatedTarget?.id.includes('locationSuggest')
      )
    ) {
      setShowLocationSuggest(false);
    }
  };

  const handleChange = (inputValue: string) => {
    setValue(inputValue);

    if (inputValue === '') {
      clearResults();
      setShowSearchHistory(true);
      setShowLocationSuggest(false);
    } else {
      setShowSearchHistory(false);
      if (shouldSearchServiceNumbers) {
        setShowLocationSuggest(false);
      } else {
        setShowLocationSuggest(true);
        fetchSuggestions(inputValue);
      }
    }
  };

  useEffect(() => {
    if (initValue !== '' && value === initValue) {
      setShowLocationSuggest(true);
      fetchSuggestions(initValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValue]);

  const handleSuggestionValue = (suggestion?: SearchSuggestion) => {
    if (suggestion) {
      const location = getLocation(suggestion);
      setShowLocationSuggest(false);
      setInputValue(direction, location.name);
      setLocation(location);
      clearResults();
      onSuggestionSelect?.(suggestion);
    } else {
      handleChange(value);
      if (shouldSearchServiceNumbers && !!handleSubmit) {
        handleSubmit();
      }
    }
  };

  if (!state) {
    return null;
  }

  const error =
    (!loading &&
      !results?.length &&
      !!value &&
      t(`search.errors.${direction}`)) ||
    state.locationError ||
    undefined;

  return (
    <Container>
      <StyledInput
        label={t(`search.selectDestination.${direction}.label`)}
        ref={fieldRef}
        value={value}
        placeholder={t(`search.selectDestination.${direction}.placeholder`)}
        icon={<StationMarker />}
        iconPosition="left"
        autoComplete="off"
        hiddenLabel
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={event => handleChange(event.target.value)}
        error={error}
        name={name ?? id}
        id={id}
        cleanable
        autoFocus
        role="combobox"
        aria-controls="locationSuggest"
        aria-autocomplete="list"
        aria-haspopup="grid"
        aria-expanded={
          showSearchHistory || showLocationSuggest ? 'true' : 'false'
        }
      />
      {/* showSearchHistory && ( */}
      {/*   <SearchHistory */}
      {/*     onItemClick={clearResults} */}
      {/*     onLastElementBlur={() => { */}
      {/*       setShowSearchHistory(false); */}
      {/*     }} */}
      {/*   /> */}
      {/* ) */}
      {showLocationSuggest && (
        <Suggestion
          connectedInputContainer={fieldRef}
          onLastElementBlur={() => {
            setShowLocationSuggest(false);
          }}
          onItemClick={handleSuggestionValue}
          results={results}
        />
      )}
    </Container>
  );
};
