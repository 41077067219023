import { ChangeEvent } from 'react';
import { Ui } from '@silvertours/front-legacy-shared';

export type TimePickerType = 'from' | 'to';

type TimePickerProps = {
  type: TimePickerType;
  id: string;
  label: string;
  value?: string;
  onChange?: (time: string, type: TimePickerType) => void;
};

type Option = {
  label: string;
  value: string;
};

const generateTimeRange = () => {
  const options: Option[] = [];
  for (let m = 0; m < 1440; m += 15) {
    const time = `${Math.floor(m / 60)
      .toString()
      .padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')}`;
    options.push({
      label: time,
      value: time,
    });
  }
  return options;
};

const TimePicker = ({ id, type, label, value, onChange }: TimePickerProps) => {
  const timeOptions = generateTimeRange();

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event.target.value, type);
  };

  return (
    <Ui.Dropdown
      id={id}
      label={label}
      value={value}
      options={timeOptions}
      onChange={handleChange}
    />
  );
};

export { TimePicker };
