import styled from '@emotion/styled';
import { Theme } from '@silvertours/front-shared';

const TableContainer = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid ${Theme.Color.grey03};
`;

const TableHead = styled.thead`
  background-color: ${Theme.Color.brand};
`;

const Row = styled.tr`
  height: 3rem;
`;

const ColumnHead = styled.th`
  padding: 0.3125rem 0.625rem;
  font-weight: bold;
  text-align: left;
  color: ${Theme.Color.white};
`;

const Cell = styled.td`
  padding: 0.3125rem 0.625rem;
`;

export { Cell, ColumnHead, Row, TableContainer, TableHead };
