import styled from '@emotion/styled';
import { Button, TertiaryText, Theme } from '@silvertours/front-shared';

const BenefitsWrapper = styled.div`
  color: ${({ theme }) => theme.color.black};
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing[40]};
  margin-bottom: ${({ theme }) => theme.spacing[40]};
`;

const BenefitText = styled(TertiaryText)`
  padding-left: 10px;
  font-weight: 400;
  text-transform: none;
`;

const BenefitWrapper = styled.div`
  display: flex;
  padding: 5px 0;
`;

const ImageWrapper = styled.div`
  padding: 10px;
  text-align: center;
`;

const JoinButton = styled(Button)`
  margin: 0.5rem 0 1.5rem;
`;

const LinkWrapper = styled.p`
  margin: 1rem 0 0;
`;

const MembershipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  ${Theme.mq('lg')} {
    padding: 1.5rem 1rem;
  }
`;

export {
  BenefitsWrapper,
  BenefitText,
  BenefitWrapper,
  ImageWrapper,
  JoinButton,
  LinkWrapper,
  MembershipWrapper,
};
