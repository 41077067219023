import styled from '@emotion/styled';

import { Theme } from '@silvertours/front-shared';

const AccordionWrapper = styled.div`
  margin-top: 2rem;

  ${Theme.mq('lg')} {
    margin-top: 2.5rem;
  }
`;

export { AccordionWrapper };
