import BannerBackgroundSwirlSvg from './swirl.svg';

import {
  BannerBackground,
  BannerBackgroundSwirl,
  BannerPromoText,
} from './Banner.styles';

export const Banner: React.FC<React.PropsWithChildren<{ title?: string }>> = ({
  children,
  title,
}) => (
  <BannerBackground>
    <BannerPromoText>{title}</BannerPromoText>
    {children}
    <BannerBackgroundSwirl
      src={BannerBackgroundSwirlSvg}
      width={360}
      height={192}
      alt=""
    />
  </BannerBackground>
);
