import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

export const FormContainer = styled(Ui.Card)`
  position: absolute;
  top: ${({ theme }) => theme.spacing['70']};
  left: ${({ theme }) => theme.spacing['40']};
  right: ${({ theme }) => theme.spacing['40']};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing['40']};
  margin-inline: auto;
  padding: ${({ theme }) => theme.spacing['40']};
  max-width: 1220px;
`;

export const Headline = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: ${({ theme }) => theme.fontSize.md.size};
  font-weight: ${({ theme }) => theme.fontWeight.heading};
  color: ${({ theme }) => theme.color.brand};
`;

export const SearchButton = styled.button`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  margin: 0;
  padding: 0 0 0 ${({ theme }) => theme.spacing['40']};
  border: 1px solid ${({ theme }) => theme.color.grey02};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  font-family: inherit;
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  font-weight: 600;
  text-align: start;
  text-transform: inherit;
  gap: ${({ theme }) => theme.spacing['30']};
  overflow: hidden;
  cursor: pointer;

  &:focus-visible {
    outline: ${({ theme }) => theme.spacing['5']} dashed
      ${({ theme }) => theme.color.secondary};
    outline-offset: ${({ theme }) => theme.spacing['10']};
  }
`;

export const HighlightedText = styled.strong`
  padding: ${({ theme }) => theme.spacing['40']};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.brand};
  font-weight: 600;
`;
