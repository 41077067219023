import Image from 'next/image';
import styled from '@emotion/styled';
import { Theme } from '@silvertours/front-shared';

const BannerBackgroundSwirl = styled(Image)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const BannerBackground = styled.div`
  background: linear-gradient(
    103.44deg,
    #6ec2b7 3.17%,
    ${Theme.Color.brand} 100%
  );
  background-repeat: no-repeat;
  box-shadow: 0 -0.125rem 0.25rem rgb(0 0 0 / 20%);
  border-radius: 0.5rem 0.5rem 0 0;
  height: 13.375rem;
  position: relative;
  overflow: hidden;
`;

const BannerPromoText = styled.p`
  width: 86%;
  margin: 1rem auto 1.5rem;
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.375rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 1;
`;

export { BannerBackground, BannerBackgroundSwirl, BannerPromoText };
