import { useId, useState } from 'react';

import { TabsContentModule } from '@silvertours/common-landingpages-view';
import {
  LinkListBlock,
  PlainContentModuleContainer,
  TabBodyContentBlock,
  TabListContentBlock,
} from '@silvertours/front-entities';
import { Destination } from '../destination';

export const Tabs = ({
  badge,
  heading,
  modules,
  rules,
  translations,
}: TabsContentModule) => {
  const tabGroup = `tabGroup-${useId()}`;
  const [currentTab, setCurrentTab] = useState(
    (modules.length && modules[0].name) || '',
  );

  if (modules.length === 0) {
    return null;
  }

  const { textStyle } = rules || {};

  return (
    <PlainContentModuleContainer>
      <TabListContentBlock
        behavior={{ onTabSelected: setCurrentTab }}
        content={{ badge, heading, modules }}
        rules={{ textStyle }}
      />
      {modules.map(subModule => (
        <TabBodyContentBlock
          key={subModule.name}
          content={{ currentTab, subModule, tabGroup }}
          rules={{ textStyle }}
        >
          {module => {
            switch (module.moduleType) {
              case 'linkList':
                return <LinkListBlock content={module} />;
              case 'destination':
              default:
                return (
                  <Destination
                    {...module}
                    rules={rules}
                    translations={translations?.destination}
                  />
                );
            }
          }}
        </TabBodyContentBlock>
      ))}
    </PlainContentModuleContainer>
  );
};
