import { Theme, colors } from './theme';
import type { ColorProps } from './theme';

type ThemeProps = {
  theme: Theme;
};

/** Color shortcut function, allows access to color property via `Theme.Color.Brand` */
const Color: ColorProps = colors.reduce(
  (previousValue, key) => ({
    ...previousValue,
    [key]: ({ theme }: ThemeProps) => theme.color?.[key],
  }),
  {},
);

export { Color };
