import { analytics } from '@silvertours/front-entities';
import { Banner } from '@silvertours/front-legacy-entities';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { Ui } from '@silvertours/front-shared';
import { useTranslations } from 'next-intl';

import { ButtonContainer } from './AppInstallBanner.styles';

export const AppInstallBanner = ({
  isShowing,
  onClose,
}: {
  isShowing: boolean;
  onClose: () => void;
}) => {
  const t = useTranslations('features.mobileAppPromotionLegacy');

  const promoTitle = t('globalApptrafficPromoTitle');

  return (
    <UiLegacy.ForegroundLayer
      id="app-install-banner"
      isShowing={isShowing}
      onClose={onClose}
    >
      <Banner title={promoTitle}>
        <ButtonContainer>
          <Ui.PlayStoreLink
            id="app-install-banner-play-store-anchor"
            href={t('playStoreLink')}
            onClick={analytics.gtm.trackIosAppLinkClicked}
          />
          <Ui.AppStoreLink
            id="app-install-banner-app-store-anchor"
            href={t('appStoreLink')}
            onClick={analytics.gtm.trackIosAppLinkClicked}
          />
        </ButtonContainer>
      </Banner>
    </UiLegacy.ForegroundLayer>
  );
};
