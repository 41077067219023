import React, { useState } from 'react';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { TimePicker } from '@silvertours/front-shared';
import styled from '@emotion/styled';
import { useTranslations } from 'next-intl';
import { analytics, useSiteInfo } from '@silvertours/front-entities';
import { useSearchContext } from '../SearchFormLegacy/context';
import { SceneProps, WizardOverlay } from './common';
import { getDays, getTime, setTime } from './datetime';

const Wrapper = styled.div`
  display: grid;
  grid-template: 1fr max-content / 100%;
  height: calc(100% + ${({ theme }) => theme.spacing[40]});
  gap: ${({ theme }) => theme.spacing[50]};
  margin-bottom: -${({ theme }) => theme.spacing[40]};
`;

type Props = SceneProps & {
  hideTimePicker?: boolean;
};

const CalendarScene = ({ onConfirm, hideTimePicker, ...props }: Props) => {
  const { state, setDate: setDateInSearchContext } = useSearchContext();
  const [dates, setDates] = useState({
    dep: state?.depDate ? new Date(state.depDate) : new Date(),
    dest: state?.destDate ? new Date(state.destDate) : new Date(),
  });
  const depDate = dates.dep;
  const destDate = dates.dest;

  const t = useTranslations('features.stage.search');

  const {
    settings: { featureToggles },
  } = useSiteInfo();

  if (!state) {
    return null;
  }

  const version = featureToggles.useNewDesignSearchFormVersion2 ? 'v2' : 'v1';

  const dayCountText = t('calendar.dayCount', {
    days: getDays(depDate, destDate),
  });

  const handleDatesConfirm = () => {
    analytics.gtm.trackConfirmDates(version);
    setDateInSearchContext('departure', depDate);
    setDateInSearchContext('destination', destDate);
    onConfirm();
  };

  const invalid = destDate <= depDate;

  const calendar = (
    <UiLegacy.Calendar
      from={depDate}
      to={destDate}
      onChange={d => {
        analytics.gtm.trackSelectDates(version);
        setDates({
          dep: d.from!,
          dest: d.to!,
        });
      }}
      vertical
    />
  );

  return (
    <WizardOverlay
      {...props}
      onConfirm={handleDatesConfirm}
      title={t('calendar.headline')}
      buttonText={dayCountText}
      buttonDisabled={invalid}
    >
      {hideTimePicker ? (
        calendar
      ) : (
        <Wrapper>
          {calendar}
          <TimePicker
            from={getTime(depDate)}
            to={getTime(destDate)}
            translations={{
              title: t('calendar.timePicker.title'),
              from: t('calendar.timePicker.from'),
              to: t('calendar.timePicker.to'),
              hours: t('calendar.timePicker.hours'),
              minutes: t('calendar.timePicker.minutes'),
              closeButton: t('calendar.timePicker.closeButton'),
              confirmButton: t('calendar.timePicker.confirmButton'),
              errorMessage: invalid ? t('errors.time') : undefined,
            }}
            onChange={times => {
              analytics.gtm.trackSelectTime(version);
              setDates({
                dep: setTime(depDate, times.from),
                dest: setTime(destDate, times.to),
              });
            }}
          />
        </Wrapper>
      )}
    </WizardOverlay>
  );
};

export { CalendarScene };
