import { useTranslations } from 'next-intl';
import { ThemeProvider } from '@emotion/react';

import {
  ContentLayout,
  RentalCompanyPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  OffersModule,
  SerpOffersModule,
  StageLegacy,
} from '@silvertours/front-features';
import { Theme } from '@silvertours/front-shared';

import { Body } from '../body';

const RentalCompanyContent = ({
  content: {
    article,
    introduction,
    links,
    offersByCategory,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<RentalCompanyPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);
  const offersTranslations = useTranslations('features.offers');

  return (
    <Body
      title={primaryHeadline}
      stage={<StageLegacy.Stage content={stage.content} />}
      content={({ containerModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp translationKey="rentalCompanyUsps" />,
        ),
        containerModule(
          'introduction-content',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={introduction?.segments ?? []}
              translations={translations}
            />
          </ThemeProvider>,
          !!introduction?.segments,
        ),
        containerModule(
          'serpOffers',
          <ThemeProvider theme={Theme.defaultTheme}>
            {!!offersByCategory.content && (
              <SerpOffersModule
                content={offersByCategory.content}
                translations={{
                  airConditioning: offersTranslations('car.airConditioning'),
                  carTypes: t.raw('offers.car.type' as any),
                  prevText: offersTranslations('prevText'),
                  nextText: offersTranslations('nextText'),
                  searchButtonText: offersTranslations('searchButtonText'),
                  disclaimertext: offersTranslations('disclaimerSerpOffers'),
                  pricePrefix: offersTranslations('pricePrefix'),
                  priceSuffix: offersTranslations('priceSuffix'),
                  serpTitle: offersTranslations('serp.title'),
                  transmissionAutomatic: offersTranslations(
                    'car.transmissionAutomatic',
                  ),
                  transmissionManual: offersTranslations(
                    'car.transmissionManual',
                  ),
                }}
              />
            )}
          </ThemeProvider>,
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule(
          'offers',
          <ThemeProvider theme={Theme.defaultTheme}>
            {!!offersByCategory.content && (
              <OffersModule
                content={offersByCategory.content}
                translations={{
                  badgeText: offersTranslations('badge'),
                  headline: offersTranslations('headline'),
                  disclaimer: offersTranslations('disclaimer'),
                  disclaimerOverlayCTA: offersTranslations(
                    'disclaimerOverlayCTA',
                  ),
                  durationFrom: offersTranslations('durationFrom'),
                  pricePrefix: offersTranslations('pricePrefix'),
                  priceSuffix: offersTranslations('priceSuffix'),
                  searchButtonText: offersTranslations('searchButtonText'),
                  offerDetailsTitle: offersTranslations('offerDetailsTitle'),
                  carTypes: t.raw('offers.car.type' as any),
                }}
              />
            )}
          </ThemeProvider>,
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule(
          'article',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={article.segments}
              translations={translations}
            />
          </ThemeProvider>,
        ),
        containerModule(
          'linkLists',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={links.content ?? []}
              translations={translations}
            />
          </ThemeProvider>,
          !!links.content.length,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { RentalCompanyContent };
