import styled from '@emotion/styled';
import { Color } from '../../../../Theme';

const StyledQuote = styled.q`
  quotes: '„ ' ' “' '»' '«';
  color: ${Color.text};
  font-style: italic;
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
`;

export { StyledQuote };
