import type { HTMLAttributes, ReactNode, SVGAttributes } from 'react';
import { StyledSvg } from './Logo.styles';

type LogoProps = Pick<
  SVGAttributes<SVGSVGElement>,
  'width' | 'height' | 'viewBox'
> &
  Pick<HTMLAttributes<HTMLElement>, 'title'> & {
    children: ReactNode;
    responsive?: boolean;
    className?: string;
  };

const Logo = ({
  width,
  height,
  viewBox,
  title,
  children,
  responsive = false,
  ...rest
}: LogoProps) => (
  <StyledSvg
    responsive={responsive}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    role="img"
    aria-label={title}
    {...rest}
  >
    {children}
  </StyledSvg>
);

export { Logo };
