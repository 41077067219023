import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const RotateAnimation = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

export const LoadingCircle = styled.span`
  display: inline-block;
  padding: 0.1em;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  box-sizing: border-box;

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 0.08em solid ${({ theme }) => theme.color.brand};
    border-right: 0.08em solid transparent;
    vertical-align: top;
    animation: ${RotateAnimation} 0.8s linear infinite;
    box-sizing: border-box;
  }
`;
