import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Styles, Theme, Ui } from '@silvertours/front-shared';

type SegmentContainerProps = {
  fillColor?: keyof Theme.ColorProps;
  hasPadding: boolean;
  spacingScale: number;
  needLayoutContentPadding?: boolean;
};

const SegmentContainer = styled.section<SegmentContainerProps>`
  display: block;
  position: relative;
  color: ${Theme.Color.text};
  background-color: ${({ theme, fillColor }) =>
    fillColor ? theme.color[fillColor] : 'transparent'};
  ${({ needLayoutContentPadding = true }) =>
    needLayoutContentPadding && Styles.Global.layoutContentOnlyPadding};

  ${({ hasPadding, spacingScale }) =>
    hasPadding &&
    css`
      padding-top: ${`${spacingScale * 0.75}rem`};
      padding-bottom: ${`${spacingScale * 0.75}rem`};
      ${Theme.mq('xl')} {
        padding-top: ${`${spacingScale * 1.25}rem`};
        padding-bottom: ${`${spacingScale * 1.25}rem`};
      }
    `};
`;

const HeaderContainer = SegmentContainer.withComponent('header');

const NavigationContainer = SegmentContainer.withComponent('nav');

type SegmentImageContainerProps = {
  isStage?: boolean;
  includeSearch?: boolean;
};

const SegmentImageContainer = styled.div<SegmentImageContainerProps>`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  display: block;

  img {
    /* Stage image usally has important details on right side, make them visible in any resolution */
    ${({ isStage, includeSearch }) =>
      isStage &&
      includeSearch &&
      css`
        width: 100%;
        object-fit: cover;
        object-position: right;
      `}
  }
  ${({ isStage, includeSearch }) =>
    isStage &&
    !includeSearch &&
    `position: relative;
    aspect-ratio: 1.66/1;
    ${Theme.mq('md')} {
      aspect-ratio: 2/1;
    }
    ${Theme.mq('lg')} {
      position: absolute;
      aspect-ratio: unset;
    }
    `}

  ${Theme.mq('lg')} {
    height: 100%;
  }
`;

const SegmentForegroundContainer = styled.div`
  left: 0;
  width: 100%;

  ${Theme.mq('lg')} {
    position: relative;
    margin: 0 auto;
  }
`;

const SegmentImage = styled(Ui.Image)`
  object-fit: cover;
`;

export {
  HeaderContainer,
  NavigationContainer,
  SegmentContainer,
  SegmentForegroundContainer,
  SegmentImageContainer,
  SegmentImage,
};
