import { useEffect, useState, useCallback } from 'react';
import type { HTMLAttributes } from 'react';
import { format } from 'date-fns/format';
import { addMonths } from 'date-fns/addMonths';
import { setHours } from 'date-fns/setHours';
import { setMinutes } from 'date-fns/setMinutes';
import { de as LocaleDe } from 'date-fns/locale/de';
import { fr as LocaleFr } from 'date-fns/locale/fr';
import { es as LocaleEs } from 'date-fns/locale/es';
import { it as LocaleIt } from 'date-fns/locale/it';
import { nl as LocaleNl } from 'date-fns/locale/nl';
import { enUS as LocaleEn } from 'date-fns/locale/en-US';
import { tr as LocaleTr } from 'date-fns/locale/tr';

import { useLocale } from '../../../../I18n';
import { dateFormats, isoDateTime } from '../../../../../strings';
import { DatePicker } from '.';
import type { DateRange } from '../types';

const INITIAL_MONTHS_MOBILE_CALENDAR = 6;
const MAXIMUM_MONTHS_IN_CALENDAR = 24;

export const addTimeToDate = (date: Date, hours: number, minutes: number) =>
  setMinutes(setHours(date, hours), minutes);

type CalendarProps = {
  from: Date;
  to: Date;
  vertical?: boolean;
  showHeader?: boolean;
  showFooter?: boolean;
  onChange?: (dateRange: DateRange) => void;
  onClose?: () => void;
  dayCountText?: string;
} & Pick<HTMLAttributes<HTMLDivElement>, 'className'>;

export const Calendar = ({
  from,
  to,
  vertical = false,
  showHeader,
  showFooter,
  dayCountText,
  className,
  onChange,
  onClose,
}: CalendarProps) => {
  const { language } = useLocale();

  const dateLocale = {
    de: LocaleDe,
    fr: LocaleFr,
    es: LocaleEs,
    it: LocaleIt,
    nl: LocaleNl,
    en: LocaleEn,
    tu: LocaleTr,
  }[language as keyof typeof dateFormats];

  const selectedDates = { from, to };

  const localDateFormats = dateFormats[language as keyof typeof dateFormats];

  const handleOnDatesChange = (dateRange: DateRange) => {
    let newDateRange = { from: new Date(), to: new Date() };
    if (
      dateRange.from &&
      dateRange.to &&
      selectedDates.from &&
      selectedDates.to
    ) {
      newDateRange = {
        from: addTimeToDate(
          dateRange.from,
          selectedDates.from.getHours(),
          selectedDates.from.getMinutes(),
        ),
        to: addTimeToDate(
          dateRange.to,
          selectedDates.to.getHours(),
          selectedDates.to!.getMinutes(),
        ),
      };
      if (
        format(dateRange.from, isoDateTime).split('T')[0] ===
        format(dateRange.to, isoDateTime).split('T')[0]
      ) {
        newDateRange = {
          from: addTimeToDate(dateRange.from, 10, 0),
          to: addTimeToDate(dateRange.to, 17, 0),
        };
      }
      if (typeof onChange !== 'undefined') {
        onChange(newDateRange);
      }
    }
  };

  const getDefaultMonth = () => {
    if (vertical || !selectedDates.from) {
      return undefined;
    }
    return selectedDates.from;
  };

  const getInitialNumberOfMonths = () => {
    if (vertical) {
      return INITIAL_MONTHS_MOBILE_CALENDAR;
    }
    return 2;
  };

  const [numberOfMonths, setNumberOfMonths] = useState(
    getInitialNumberOfMonths(),
  );

  const addMoreMonths = useCallback(() => {
    setNumberOfMonths(Math.min(numberOfMonths + 4, MAXIMUM_MONTHS_IN_CALENDAR));
  }, [numberOfMonths]);

  useEffect(() => {
    // Reset months on viewport change
    setNumberOfMonths(getInitialNumberOfMonths());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vertical]);

  const now = new Date();
  const calendarRange = {
    start: now,
    end: addMonths(now, MAXIMUM_MONTHS_IN_CALENDAR),
  };

  return (
    <DatePicker
      disabled={{ before: new Date() }}
      numberOfMonths={numberOfMonths}
      fromMonth={calendarRange.start}
      toMonth={calendarRange.end}
      from={selectedDates.from ? selectedDates.from : undefined}
      to={selectedDates.to ? selectedDates.to : undefined}
      locale={dateLocale}
      defaultMonth={getDefaultMonth()}
      scrollToSelectedMonth={vertical}
      dateFormat={
        vertical ? localDateFormats.dayDateLongYear : localDateFormats.dayDate
      }
      vertical={vertical}
      dayCountText={dayCountText}
      showHeader={!!showHeader}
      showFooter={!!showFooter && !!dayCountText}
      className={className}
      onScrollToBottom={addMoreMonths}
      onDatesChange={handleOnDatesChange}
      onClose={onClose}
    />
  );
};
