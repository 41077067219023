import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react';

import React from 'react';
import { InteractiveText } from '../../Text';

import { ButtonComponent } from './Button.styles';

export type ButtonSize = 'small' | 'large';

type ButtonCommonProps = {
  icon?: ReactElement;
  iconAlignment?: 'left' | 'right';
  size?: ButtonSize;
  variant?: 'primary' | 'secondary';
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  textId?: string;
};

export type ButtonProps = PropsWithChildren<ButtonCommonProps> &
  (
    | (ButtonHTMLAttributes<HTMLButtonElement> & {
        href?: never;
        target?: never;
        rel?: never;
      })
    | (Pick<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        'href' | 'target' | 'rel' | 'className'
      > & { type?: never })
  );

/** Renders a button or a link looking like a button. */
const Button = React.forwardRef(
  (
    {
      type = 'button',
      children,
      icon,
      iconAlignment = 'left',
      size = 'large',
      variant = 'primary',
      fullWidth = false,
      loading = false,
      disabled = false,
      className,
      textId,
      ...props
    }: ButtonProps,
    ref: React.Ref<HTMLElement | undefined>,
  ) => {
    const isDisabled = disabled === true;
    const isSmallButton = size === 'small';
    const isSecondaryButton = variant === 'secondary';
    const isFullWidth = fullWidth === true;

    return (
      <ButtonComponent
        ref={ref as any}
        as={props.href ? 'a' : undefined}
        {...props}
        type={props.href ? undefined : type}
        disabled={props.href ? undefined : disabled}
        iconAlignment={iconAlignment!}
        isDisabled={isDisabled}
        isSmallButton={isSmallButton}
        isSecondaryButton={isSecondaryButton}
        isFullWidth={isFullWidth}
        aria-busy={loading ? 'true' : undefined}
        aria-disabled={props.href && isDisabled ? 'true' : undefined}
        className={className}
      >
        {icon}
        {children && (
          <InteractiveText
            id={textId}
            inline
            textColor="current"
            size={isSmallButton ? 'small' : 'normal'}
          >
            {children}
          </InteractiveText>
        )}
      </ButtonComponent>
    );
  },
);

export { Button };
