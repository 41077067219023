import { Marker } from '@googlemaps/markerclusterer';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { Theme, UiLegacy } from '@silvertours/front-legacy-shared';
import { ReactNode } from 'react';

import { MarkerIconWrapper, MarkerWrapper } from './CustomMarker.styles';
import { Position, Station } from '../types';
import { StationInfoBox } from '../StationInfoBox';

type CustomMarkerProps = {
  children: ReactNode;
  deselect: () => void;
  id?: string;
  isSelected?: boolean;
  position: Position;
  select: () => void;
  setMarkerRef?: (marker: Marker | null, key: string) => void;
  station: Station;
};

export const CustomMarker: React.FC<CustomMarkerProps> = ({
  children,
  deselect,
  id,
  isSelected = false,
  position,
  select,
  setMarkerRef,
  station,
}) => {
  const isMobile = Theme.useIsMobileTouchDevice();
  const handleClose = () => {
    deselect();
  };

  const ref = (marker: Marker | null) => {
    if (setMarkerRef && id) {
      setMarkerRef(marker, id);
    }
  };

  return (
    <>
      <AdvancedMarker key={id} ref={ref} position={position} onClick={select}>
        <MarkerWrapper isSelected={isSelected}>
          <MarkerIconWrapper isSelected={isSelected}>
            <UiLegacy.SvgIcon name="mapMarkerBox" />
          </MarkerIconWrapper>
          {children}
        </MarkerWrapper>
      </AdvancedMarker>
      {isSelected && !isMobile && (
        <InfoWindow position={position} pixelOffset={[0, -50]}>
          <StationInfoBox
            closable
            handleClose={handleClose}
            station={station}
            onClick={select}
            selected={isSelected}
          />
        </InfoWindow>
      )}
    </>
  );
};
