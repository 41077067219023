/* eslint-disable react/destructuring-assignment */
import { Theme } from '@silvertours/front-shared';
import { useMedia } from 'react-use';

import { SegmentImage, SegmentImageContainer } from './Segment.styles';
import { ImageSegmentProps, ConfigurableSegmentProps } from './props';

const isImageSegment = (
  props: ConfigurableSegmentProps,
): props is ImageSegmentProps =>
  Object.prototype.hasOwnProperty.call(props, 'image');

const SegmentImageBackground = (props: ConfigurableSegmentProps) => {
  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    true,
  );

  if (
    !isImageSegment(props) ||
    (props.isStage && isMobile && props.includeSearch)
  ) {
    return null;
  }

  return (
    <SegmentImageContainer
      id={props.id}
      isStage={props.isStage}
      includeSearch={props.includeSearch}
    >
      <SegmentImage {...props.image} fill priority />
    </SegmentImageContainer>
  );
};

export { SegmentImageBackground };
