import styled from '@emotion/styled';

import { Color } from '../../../../Theme';

const CardContainer = styled.div<{ hasBorder: boolean }>`
  &:empty {
    display: none;
  }

  &:not(:empty) {
    border: ${({ hasBorder }) => (hasBorder ? `1px solid ${Color.grey03}` : 0)};
    border-radius: 1px;
    container-type: inline-size;
  }
`;

export { CardContainer };
