import styled from '@emotion/styled';
import { Theme } from '@silvertours/front-shared';

const LogoList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 1.25rem;

  ${Theme.mq('lg')} {
    flex-direction: row;
    gap: 0;
  }
`;

const GarantieWrapper = styled.a`
  position: relative;
  height: 5.625rem;
  margin-bottom: 1.25rem;
`;

const MgpWrapper = styled.li`
  height: 5.625rem;
`;

const CarigamiWrapper = styled.li`
  svg {
    height: 1.688rem;

    ${Theme.mq('md')} {
      height: 2.375rem;
    }

    ${Theme.mq('lg')} {
      height: 3.063rem;
    }
  }

  & #logo-claim {
    display: none;

    ${Theme.mq('lg')} {
      display: block;
    }
  }
`;

export { LogoList, GarantieWrapper, MgpWrapper, CarigamiWrapper };
