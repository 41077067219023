import { css } from '@emotion/react';
import { Theme } from '@silvertours/front-shared';
import { Columns } from '../rule';

export const formatColumns = ([
  span,
  breakpoint,
  { override = false, condition = true } = {},
]: Columns) => {
  if (!condition) {
    return '';
  }

  const columnStyle = `grid-column: span ${span}${
    override ? ' !important' : ''
  };`;

  if (breakpoint) {
    return css`
      ${Theme.mq(breakpoint)} {
        ${columnStyle}
      }
    `;
  }

  return css`
    ${columnStyle}
  `;
};
