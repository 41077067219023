import { useTranslations } from 'next-intl';
import { ThemeProvider } from '@emotion/react';

import {
  ContentLayout,
  PillarPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  StageLegacy,
} from '@silvertours/front-features';
import { Theme } from '@silvertours/front-shared';

import { Body } from '../body';

const PillarContent = ({
  content: {
    article,
    globalRating,
    introduction,
    links,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<PillarPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);

  return (
    <Body
      title={primaryHeadline}
      stage={<StageLegacy.Stage content={stage.content} />}
      content={({ containerModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
        containerModule(
          'introduction-content',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={introduction?.segments ?? []}
              translations={translations}
            />
          </ThemeProvider>,
          !!introduction?.segments,
        ),
        containerModule(
          'article',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={article.segments}
              translations={translations}
            />
          </ThemeProvider>,
        ),
        containerModule(
          'linkLists',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={links.content ?? []}
              translations={translations}
            />
          </ThemeProvider>,
          !!links.content.length,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { PillarContent };
