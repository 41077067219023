import { useRouter } from 'next/router';
import { useMemo, useCallback } from 'react';
import { Session } from '@silvertours/front-shared';

export const SESSION_STORAGE_NAME_FOR_FIND_CLICK = 'userClickOnFindTimestamp';
export const SEARCH_FORM_SESSION_STORAGE_KEY = 'searchForm';

export const useSession = () => {
  const router = useRouter();

  const hasUrlQueryParams = useMemo(
    () =>
      Object.keys(router.query).some(key =>
        [
          'dep',
          'dest',
          'depAirport',
          'destAirport',
          'depDate',
          'destDate',
          'customerAge',
        ].includes(key),
      ),
    [router.query],
  );

  // Initialize the session from storage
  const getSessionData = useCallback(
    (cityName?: string) => {
      // only restore from session if we are not on a landing page with default values
      if (hasUrlQueryParams || cityName) {
        return null;
      }

      const data = Session.SessionStorageManager.getData(
        SEARCH_FORM_SESSION_STORAGE_KEY,
      );

      if (!data) {
        return null;
      }

      // check for expired dates in session
      const today = new Date();
      const depDate = new Date(data.depDate);
      const destDate = new Date(data.destDate);
      if (depDate <= today || destDate <= today) {
        return null;
      }

      return data;
    },
    [hasUrlQueryParams],
  );

  // Track current click timestamp
  const trackClickTimestamp = () => {
    Session.SessionStorageManager.setData(
      SESSION_STORAGE_NAME_FOR_FIND_CLICK,
      Date.now(),
    );
  };

  // Remove current click timestamp
  const removeClickTimestamp = () => {
    Session.SessionStorageManager.removeData(
      SESSION_STORAGE_NAME_FOR_FIND_CLICK,
    );
  };

  return { getSessionData, trackClickTimestamp, removeClickTimestamp };
};
