import {
  LanguageCode,
  LocaleValue,
  MarketCode,
} from '@silvertours/common-landingpages-view';

type Weekday = 'always' | 'workdays' | 'weekends' | 'saturday' | 'sunday';

type OpenCloseTimes = {
  open: string;
  close: string;
};

type OpenHoursCommon = Partial<Record<Weekday, OpenCloseTimes>>;

type OpenHoursExceptions = Record<string, OpenCloseTimes | null>;

type InfolineTimes = {
  common: OpenHoursCommon;
  exceptions: OpenHoursExceptions | [];
};

type SessionData = {
  securityTokenValue: string;
  isMobileUserAgent: boolean;
  gtmEnvironment: 'dev' | 'live';
  anonymizeIP: boolean;
};

type FeatureToggles = {
  waitForResults: boolean;
  erv: boolean;
  maintenanceInfo: boolean;
  showScaMessage: boolean;
  hideInfoline: boolean;
  hideInfolineOnIndex: boolean;
  isLoginActive: boolean;
  useElasticSearch: boolean;
  showSurveyOnSuccess: boolean;
  showMarketPicker: boolean;
  showEnglishInMarketPicker: boolean;
  skipSecondScaCall: boolean;
  useSavingBadge: boolean;
  trustElementOnStageImage: boolean;
  useNewDesignFooter: boolean;
  useNewDesignSearchForm: boolean;
  useNewDesignSearchFormVersion2: boolean;
};

// Feature toggles which can be dynamically updated, for instance by AB tests
// To set a toggle, the following snippet can be used:
//
// window.featureToggles = window.featureToggles || [];
// window.featureToggles.push(['useNewDesignSearchFormVersion2', true]);
//
// This always works, regardless of whether the client app is already loaded or not.
export const DYNAMIC_FEATURE_TOGGLES: (keyof FeatureToggles)[] = [
  'useNewDesignSearchForm',
  'useNewDesignSearchFormVersion2',
];

type SiteSettings = {
  featureToggles: FeatureToggles;
  /** Is this site rendered in an app’s Web view? */
  isAppMode: boolean;
  /** Has a bot been detected? */
  isBotCall: boolean;
  /** Has an user from Customer Excellence been detected? */
  isInfolineCall: boolean;
  /** Has an internal user been detected? */
  isInternalCall: boolean;
  /** Has a Trusted Web Activity been detected? */
  isTwa: boolean;
  locale: LocaleValue;
  language: LanguageCode;
  validLanguages: Array<LanguageCode>;
  market: MarketCode;
  validMarkets: Array<MarketCode>;
  /** Phone number for Customer Excellence */
  phoneNumber: string;
  sessionData: SessionData;
};

type SiteInfo = {
  settings: SiteSettings;
  openingTimes: InfolineTimes;
  settingsLoaded: boolean;
};

export type { InfolineTimes, SessionData, SiteInfo, SiteSettings };
