import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { desaturate, lighten, tint } from 'polished';

import type { Theme } from '../..';

type ButtonComponentProps = {
  iconAlignment: 'left' | 'right';
  isDisabled: boolean;
  isSecondaryButton: boolean;
  isSmallButton: boolean;
  isFullWidth: boolean;
};

const getPrimaryStyles = (
  defaultColor: string,
  highlightColor: string,
  theme: Theme.Theme,
) => css`
  color: ${theme.color.white};
  background-color: ${defaultColor};
  transition: background 1s;

  @media (hover: hover) {
    &:hover {
      background-color: ${highlightColor};
      background-image: radial-gradient(
        circle,
        transparent 1%,
        ${highlightColor} 1%
      );
      background-position: center;
      background-size: 15000%;
    }
  }

  &:active {
    background-color: ${tint(0.15, highlightColor)};
    background-size: 100%;
    transition: background 0s;
  }
`;

const getPrimaryDisabledStyles = (theme: Theme.Theme) => css`
  color: ${theme.color.grey03};
  background-color: ${theme.color.grey05};
`;

const getSecondaryStyles = (
  defaultColor: string,
  highlightColor: string,
  isSmallButton: boolean,
  theme: Theme.Theme,
) => css`
  padding: ${isSmallButton ? '0.5rem 0.75rem' : '0.875rem 1.875rem'};
  border: 2px solid currentcolor;
  color: ${defaultColor};
  background-color: ${theme.color.white};
  transition-property: color, background, border-color;
  transition-duration: 1s;

  @media (hover: hover) {
    &:hover {
      color: ${highlightColor};
      background-color: ${theme.color.white};
      background-image: radial-gradient(
        circle,
        transparent 1%,
        ${theme.color.white} 1%
      );
      background-position: center;
      background-size: 15000%;
    }
  }

  &:active {
    color: ${highlightColor};
    background-color: ${tint(0.8, highlightColor)};
    background-size: 100%;
    transition: background 0s;
  }
`;

const getSecondaryDisabledStyles = (
  isSmallButton: boolean,
  theme: Theme.Theme,
) => css`
  padding: ${isSmallButton ? '0.5rem 0.875rem' : '0.875rem 1.875rem'};
  border: 2px solid ${theme.color.grey04};
  color: ${theme.color.grey03};
  background-color: ${theme.color.white};
`;

const ButtonComponent = styled.button<ButtonComponentProps>`
  display: ${({ isFullWidth }) => (isFullWidth ? 'flex' : 'inline-flex')};
  flex-direction: ${({ iconAlignment }) =>
    iconAlignment === 'right' ? 'row-reverse' : ''};
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: ${({ isSmallButton }) =>
    isSmallButton ? '0.625rem 1rem' : '1rem 2rem'};
  border: 0;
  outline: 0;
  border-radius: 0.5rem;
  overflow: visible;
  box-sizing: border-box;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '')};
  max-width: 100%;
  white-space: nowrap;
  text-decoration: none;
  text-transform: none;
  fill: currentcolor;
  background-position: center;
  -webkit-tap-highlight-color: transparent;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  appearance: none;

  &:focus-visible {
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }

  ${({ theme, isSecondaryButton, isSmallButton, isDisabled }) => {
    const defaultColor = theme.color.brand;
    const highlightColor = lighten(0.3, desaturate(0.6, defaultColor!));

    if (isSecondaryButton) {
      if (isDisabled) {
        return getSecondaryDisabledStyles(isSmallButton, theme);
      }

      return getSecondaryStyles(
        defaultColor!,
        highlightColor,
        isSmallButton,
        theme,
      );
    }

    if (isDisabled) {
      return getPrimaryDisabledStyles(theme);
    }

    return getPrimaryStyles(defaultColor!, highlightColor, theme);
  }};
`;

export { ButtonComponent };
