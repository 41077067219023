import styled from '@emotion/styled';
import { Subtitle, Theme } from '@silvertours/front-shared';
import ArrowDown from '@engineering/icons/arrow-down';
import type { HasColumns } from '../../../rule';
import { formatColumns } from '../../../particle';

/* @TODO: Add correct FLOYT colors to theme. */

const ArrowDownIcon = styled(ArrowDown)`
  width: 1.5rem;
  height: 1.5rem;

  ${Theme.mq('sm')} {
    width: 2rem;
    height: 2rem;
  }
`;

const AccordionItemTitle = styled(Subtitle)`
  grid-column: span 10;
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
`;

const AccordionItemWrapper = styled.details<{ isFirst: boolean }>`
  border-top: ${({ isFirst }) => (isFirst ? '' : '1px solid #b6bfc1')};

  &[open] ${ArrowDownIcon} {
    transform: scaleY(-1);
  }
`;

const AccordionItemPromptWrapper = styled.summary`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;

    ${AccordionItemTitle} {
      transform: translateX(0.5rem);
    }
  }

  &::-webkit-details-marker {
    display: none;
  }

  ${Theme.mq('lg')} {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
`;

const AccordionItemContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 1.5rem;
  margin-bottom: 1rem;

  ${Theme.mq('lg')} {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
`;

const AccordionItemArrow = styled.span`
  grid-column: span 2;
  display: flex;
  justify-content: end;
  fill: ${({ theme }) => theme.color.text};
`;

const AccordionFigure = styled.figure<{ rules: HasColumns }>`
  ${({ rules }) => rules.columns?.map(formatColumns)};

  filter: drop-shadow(0 10px 25px rgb(0 0 0 / 8%));

  img {
    border-radius: 0.75rem;
  }
`;

export {
  ArrowDownIcon,
  AccordionItemWrapper,
  AccordionItemPromptWrapper,
  AccordionItemContentWrapper,
  AccordionItemTitle,
  AccordionItemArrow,
  AccordionFigure,
};
