import styled from '@emotion/styled';
import { Theme } from '@silvertours/front-shared';

type AffiliateProps = {
  isAffiliate?: boolean;
};

const HeaderContainer = styled.header`
  color: ${Theme.Color.text};
  background-color: ${Theme.Color.white};
  box-sizing: border-box;
  height: 3rem;
  margin-bottom: -3px;

  ${Theme.mq('sm')} {
    height: 4.375rem;
    margin: 0;
  }
`;

const HeaderLogoWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    display: block;
    flex: 0 0 auto;
    width: auto;
    height: 27px;
    ${Theme.mq('sm')} {
      height: 49px;
    }
  }

  /* hack to reduce the viewBox size on small screens for the stacked BM logo */
  html[lang='de'] & {
    width: 155px !important;
    ${Theme.mq('sm')} {
      width: 265px !important;
    }
    ${Theme.mq('lg')} {
      width: auto !important;
    }
  }

  .logo-bm-part-1 {
    transform: translate(9px, 0.6px) scale(0.83);
  }

  .logo-bm-part-2 {
    transform: translate(-174.2px, 33.2px) scale(0.83);
  }

  .logo-cg-title {
    transform: translate(0, 20px);
  }

  .logo-claim {
    display: none;
  }

  ${Theme.mq('lg')} {
    .logo-bm-part-1,
    .logo-bm-part-2,
    .logo-cg-title {
      /* translateZ(0) fixes an issue with iOS Safari <= 14 when rotating the device */
      transform: translateZ(0);
    }

    .logo-claim {
      display: block;
    }
  }
`;

const LayoutContainer = styled.div<AffiliateProps>`
  width: 100%;
  background-color: ${({ theme, isAffiliate }) =>
    isAffiliate ? theme.color.white : theme.color.grey04};
`;

const PageBaseContainer = styled.div<AffiliateProps>`
  position: relative;
  min-width: 20rem;

  ${Theme.mq('lg')} {
    min-height: ${({ isAffiliate }) => (isAffiliate ? '620px' : 'auto')};
  }

  ${Theme.mq('xl')} {
    margin: 0 auto;
    max-width: 1920px;
    overflow-x: hidden;
  }
`;

const PageContentContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
`;

const FooterBaseContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  contain: content;
`;

const FooterList = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  margin: 1.5rem auto 0;
  padding: 0;
  max-width: 21em;
  contain: content;

  li {
    flex-basis: 50%;
  }

  ${Theme.mq('lg')} {
    justify-content: center;
    max-width: fit-content;

    li {
      flex-basis: auto;
    }
  }

  a {
    color: ${Theme.Color.white};
    display: inline-block;
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    text-decoration: none;
  }
`;

const FooterContainer = styled.footer`
  color: ${Theme.Color.white};
  background-color: ${Theme.Color.brand};
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 1rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  contain: layout style;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: -1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    background: ${Theme.Color.white};
    border: 2px solid ${Theme.Color.brand};
    border-radius: 100%;
    transform: translate3d(-50%, 0, 0);
    cursor: pointer;

    svg {
      fill: ${Theme.Color.brand};
      height: 0.9375rem;
      width: 0.9375rem;
    }
  }
`;

export {
  HeaderContainer,
  HeaderLogoWrapper,
  LayoutContainer,
  PageBaseContainer,
  PageContentContainer,
  FooterBaseContent,
  FooterList,
  FooterContainer,
};
