import React from 'react';

import {
  ContentLayout,
  ErrorPageContent,
} from '@silvertours/common-landingpages-view';
import { BenefitsLegacy, StageLegacy } from '@silvertours/front-features';

import { Body } from './body';

const ErrorContent = ({
  content: { globalRating, statusCode },
  ...commonProps
}: ContentLayout<ErrorPageContent>) => (
  <Body
    stage={<StageLegacy.Stage statusCode={statusCode} />}
    content={({ containerModule }) => [
      containerModule(
        'usp',
        <BenefitsLegacy.Usp
          rating={globalRating.content}
          translationKey="errorUsps"
        />,
      ),
    ]}
    {...commonProps}
  />
);

export { ErrorContent };
