import { useTranslations } from 'next-intl';
import { analytics, useSiteInfo } from '@silvertours/front-entities';
import { SelectStation } from './SelectStation';
import { SceneProps, WizardOverlay } from '../common';
import { RentalDirection } from '../../SearchFormLegacy/context/types';

type Props = {
  direction: RentalDirection;
} & SceneProps;

const SelectStationScene = ({ direction, ...props }: Props) => {
  const t = useTranslations('features.stage.search');
  const {
    settings: { featureToggles },
  } = useSiteInfo();

  const version = featureToggles.useNewDesignSearchFormVersion2 ? 'v2' : 'v1';
  const handleSuggestionSelect = () => {
    analytics.gtm.trackSelectStation(version);
    props.onConfirm();
  };
  return (
    <WizardOverlay {...props} title={t('selectDestination.headline')}>
      <SelectStation
        direction={direction}
        id={direction.slice(0, 3)}
        onServiceNumbersSubmit={() => {}}
        onSuggestionSelect={handleSuggestionSelect}
      />
    </WizardOverlay>
  );
};

export { SelectStationScene };
