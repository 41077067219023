import { useState, useEffect } from 'react';
import { format } from 'date-fns/format';
import { de as LocaleDe } from 'date-fns/locale/de';
import { fr as LocaleFr } from 'date-fns/locale/fr';
import { es as LocaleEs } from 'date-fns/locale/es';
import { it as LocaleIt } from 'date-fns/locale/it';
import { nl as LocaleNl } from 'date-fns/locale/nl';
import { enUS as LocaleEn } from 'date-fns/locale/en-US';
import { tr as LocaleTr } from 'date-fns/locale/tr';

import { useLocale } from '../../../../I18n';
import { dateFormats } from '../../../../../strings';

const useFormattedDate = (date?: Date) => {
  const { language } = useLocale();

  const dateLocale = {
    de: LocaleDe,
    fr: LocaleFr,
    es: LocaleEs,
    it: LocaleIt,
    nl: LocaleNl,
    en: LocaleEn,
    tu: LocaleTr,
  }[language as keyof typeof dateFormats];

  const localDateFormats = dateFormats[language as keyof typeof dateFormats];

  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    if (date) {
      const localDate = format(date, localDateFormats.dayDateLongYear, {
        locale: dateLocale,
      });
      setFormattedDate(localDate);
    }
  }, [date, dateLocale, localDateFormats.dayDateLongYear]);

  return formattedDate;
};

export { useFormattedDate };
