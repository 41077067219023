import styled from '@emotion/styled';
import { interactiveText, StyledTextProps } from '@silvertours/front-shared';

type BannerPromoTextProps = StyledTextProps;

const BannerBackground = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(to right,  ${theme.color.primaryHighlight}, ${theme.color.brand})`};
`;

const BannerPromoText = styled.p<BannerPromoTextProps>`
  ${interactiveText}
  margin: auto;
`;

export { BannerBackground, BannerPromoText };
