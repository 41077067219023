import type { ReactNode } from 'react';

import { LanguageCode } from '@silvertours/common-landingpages-view';

import * as String from '../../strings';
import { Anchor } from './PhoneNumber.styles';

type PhoneNumberProps = {
  className?: string;
  phoneNumber: string;
  language: LanguageCode;
  icon?: ReactNode;
};

const PhoneNumber = ({
  className,
  phoneNumber,
  language,
  icon,
}: PhoneNumberProps) => {
  const pn = String.parsePhoneNumber(phoneNumber, language);

  return (
    <Anchor
      className={className}
      href={pn.url}
      target="_system"
      hasText={!!pn.national}
    >
      {icon}
      {pn.national && <span>{pn.national}</span>}
    </Anchor>
  );
};

export { PhoneNumber };
