import { useCallback, useState } from 'react';
import type { FormEvent } from 'react';
import { useTranslations } from 'next-intl';
import Search from '@engineering/icons/search';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { Ui, Button, Theme } from '@silvertours/front-shared';
import { analytics, useSiteInfo } from '@silvertours/front-entities';
import { TooltipBadge } from '@silvertours/front-legacy-entities';
import { SearchFormOptions } from './SearchFormOptions';
import { SearchInput } from './SearchInput';
import { AgeDropdown } from './AgeDropdown';
import { Logo } from './Logo';
import { SearchProvider, useSearchContext } from './context';
import { useSession } from './hooks';
import { matchError, useSubmitHandler } from './hooks/useSubmitHandler';

import {
  StyledForm,
  Header,
  Title,
  Subtitle,
  ButtonWrapper,
  FooterLink,
  FormContainer,
  CloseIcon,
  OptionsOpenIcon,
  OptionsCloseIcon,
} from './SearchForm.styles';
import { isFormError } from './context/types';
import { SearchProviderProps } from './context/Provider';
import { getOptionsParam } from './utils';

type SearchFormProps = {
  customColor?: string;
  stageTitle?: string;
  isAffiliate?: boolean;
  showPoweredBy?: boolean;
};

const SearchForm = ({
  customColor,
  isAffiliate,
  stageTitle,
  showPoweredBy,
}: SearchFormProps) => {
  const {
    settings: { featureToggles },
  } = useSiteInfo();

  const { setCustomerAge, setDate, setError, setShowDestLocation, state } =
    useSearchContext();

  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const submitHandler = useSubmitHandler();

  const { trackClickTimestamp, removeClickTimestamp } = useSession();
  const [loading, setLoading] = useState(false);

  const isMobileTouchDevice = Theme.useIsMobileTouchDevice();
  const [showOptions, setShowOptions] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);

  // Show or hide the options on the right side of the form
  const toggleOptions = () => {
    const showOptionsValue = !showOptions;
    setShowOptions(showOptionsValue);
    analytics.gtm.trackToggleMoreOptions(showOptionsValue);
  };

  const handleOptionsChange = (optionsId: string) => {
    let checked = checkedOptions;
    if (checked.includes(optionsId)) {
      checked = checked.filter(key => key !== optionsId);
    } else {
      checked = [...checked, optionsId];
    }
    setCheckedOptions(checked);
  };

  const onError = useCallback((error: any) => {
    setLoading(false);
    removeClickTimestamp();

    const { type: kind, message } = matchError(error);

    if (isFormError(kind)) {
      setError({ kind, message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchSubmit = async () => {
    setLoading(true);
    trackClickTimestamp();

    await submitHandler(
      state,
      getOptionsParam(checkedOptions),
      () => {
        setLoading(false);
      },
      onError,
    );
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    handleSearchSubmit();
  };

  if (!state) {
    return null;
  }

  return (
    <div>
      <Header>
        <Title customColor={customColor}>{stageTitle || t('title')}</Title>
        {!isAffiliate && <Subtitle>{t('subtitle')}</Subtitle>}
        {featureToggles.useSavingBadge ? (
          <TooltipBadge
            badgeText={t('savingBadge')}
            tooltipTitle={t('savingBadge')}
            tooltipText={t.raw('saving.tooltip.text')}
            onHover={() => analytics.gtm.trackSavingHover()}
          />
        ) : (
          <TooltipBadge
            badgeText={t('cancellationBadge')}
            tooltipTitle={t('cancellationBadge')}
            tooltipText={t.raw('cancellation.tooltip.text')}
            onHover={() => analytics.gtm.trackCancellationHover()}
          />
        )}
      </Header>

      <StyledForm onSubmit={handleSubmit} id="search" role="search">
        <FormContainer>
          <SearchInput
            id="dep"
            onServiceNumbersSubmit={handleSearchSubmit}
            direction="departure"
            label={t('departure.label')}
            placeholder={t('departure.placeholder')}
            icon={<Search />}
          />
          {!state.showDestLocation && (
            <UiLegacy.Checkbox
              label={t('destination.label')}
              id="destSelect"
              checked={state.showDestLocation}
              onChange={() => setShowDestLocation(true)}
            />
          )}
          {state.showDestLocation && (
            <SearchInput
              id="dest"
              direction="destination"
              label={t('destination.label')}
              placeholder={t('destination.placeholder')}
              icon={
                <CloseIcon
                  className="inputCloseIcon"
                  onClick={() => setShowDestLocation(false)}
                />
              }
            />
          )}
          <UiLegacy.DateRangePicker
            from={new Date(state.depDate)}
            to={new Date(state.destDate)}
            error={state.dateError || ''}
            onDatesChange={dates => {
              setDate('departure', dates.from!);
              setDate('destination', dates.to!);
            }}
            vertical={isMobileTouchDevice}
          />
          <AgeDropdown
            value={state.customerAge}
            label={t('age.label')}
            onChange={setCustomerAge}
          />
          <ButtonWrapper customColor={customColor}>
            <Button
              id="find"
              loading={loading}
              type="submit"
              fullWidth
              textId="find-button-text"
            >
              {t('button.label')}
            </Button>
          </ButtonWrapper>
          {state.axiosError && (
            <Ui.ErrorMessage>{t('errorTexts.request')}</Ui.ErrorMessage>
          )}

          {isAffiliate && showPoweredBy && !isMobileTouchDevice && <Logo />}

          {!isAffiliate && (
            <FooterLink iconOnLeft={showOptions}>
              <Ui.TextLink
                id="more-options-text"
                as="button"
                onClick={toggleOptions}
              >
                {t('options.label')}{' '}
                {checkedOptions.length > 0 && `(${checkedOptions.length})`}
                {showOptions ? (
                  <OptionsOpenIcon id="more-options-open-icon" />
                ) : (
                  <OptionsCloseIcon id="more-options-close-icon" />
                )}
              </Ui.TextLink>
            </FooterLink>
          )}
        </FormContainer>

        {showOptions && (
          <SearchFormOptions
            onChange={handleOptionsChange}
            setShowOptions={setShowOptions}
            checked={checkedOptions}
          />
        )}
      </StyledForm>
    </div>
  );
};

const SearchFormWithContext = ({
  pageData,
  ...formProps
}: SearchFormProps & SearchProviderProps) => (
  <SearchProvider pageData={pageData}>
    <SearchForm {...formProps} />
  </SearchProvider>
);

export { SearchFormWithContext as SearchForm };
