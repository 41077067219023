import styled from '@emotion/styled';
import Info from '@engineering/icons/info';
import { Theme } from '@silvertours/front-shared';

type AffiliateProps = {
  isAffiliate?: boolean;
  hasCustomColor?: boolean;
};

const Badge = styled.div<AffiliateProps>`
  position: absolute;
  right: 0;
  top: -1.625rem;
  height: 2.125rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.color.secondary};
  background-image: ${({ theme }) =>
    Theme.getLinearGradient(theme.gradient.orange, 'to left')};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: 700;
  padding: 0 1rem;
  border-radius: 100px;

  ${({ theme }) => theme.mq('md')} {
    top: 0;
    right: -1.5625rem;
  }

  svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: ${Theme.Color.white};
    vertical-align: middle;
    cursor: pointer;
  }
`;

const InfoIcon = styled(Info)`
  width: 1.6rem;
  height: 1.6rem;
  fill: currentcolor;
  vertical-align: middle;
  cursor: pointer;
`;

export { Badge, InfoIcon };
