import React, { Fragment } from 'react';
import {
  Document,
  DocumentLink,
  DocumentMetaItem,
} from '@silvertours/common-landingpages-view';
import { useMessages, useTranslations } from 'next-intl';
import { Features } from '@silvertours/common-landingpages-i18n';
import { useDocumentUrls } from './hooks/useDocumentUrls';

type HeadProps = {
  document: Document | null;
  headComponent: React.ComponentType<{ children: React.ReactNode[] }>;
};

const metaKey = (
  { charSet, content, httpEquiv, name, property }: DocumentMetaItem,
  index: number,
) => {
  if (charSet) {
    return charSet;
  }

  if (name && content) {
    return `${name}-${content}`;
  }

  if (property && content) {
    return `${property}-${content}`;
  }

  if (httpEquiv && content) {
    return `${httpEquiv}-${content}`;
  }

  return `${index}`;
};

const Head = ({ document, headComponent: HeadComponent }: HeadProps) => {
  const { canonicalUrl, hreflangUrls } = useDocumentUrls();
  const title = document?.title || '';

  const messages = useMessages() as unknown as { features: Features };
  const { links, meta: metaItems } = messages.features.documentLegacy;
  const linkTranslations = useTranslations('features.documentLegacy.links');
  const metaTranslations = useTranslations('features.documentLegacy.meta');

  return (
    <HeadComponent>
      <title>{title}</title>
      <meta property="og:title" content={title} />

      {Object.keys(metaItems).map((item, index) => {
        const meta = metaTranslations.raw(item) as DocumentMetaItem;
        return (
          <Fragment key={metaKey(meta, index)}>
            <meta {...meta} />
            {meta.name === 'description' && (
              <meta property="og:description" content={meta.content} />
            )}
          </Fragment>
        );
      })}
      {document?.meta.map(meta => (
        <Fragment key={meta.name}>
          <meta {...meta} />
          {meta.name === 'description' && (
            <meta property="og:description" content={meta.content} />
          )}
        </Fragment>
      ))}
      {Object.keys(links).map(link => {
        const linkData = linkTranslations.raw(link) as DocumentLink;
        return <link {...linkData} key={linkData.href} />;
      })}
      {hreflangUrls.map(hreflangUrl => (
        <link {...hreflangUrl} key={hreflangUrl.href} />
      ))}
      <link {...canonicalUrl} />
    </HeadComponent>
  );
};

export { Head };
