import { useTranslations } from 'next-intl';
import { ThemeProvider } from '@emotion/react';

import {
  ContentLayout,
  MinimalPageContent,
} from '@silvertours/common-landingpages-view';
import { ContentArticle } from '@silvertours/front-features';
import { Theme } from '@silvertours/front-shared';

import { Body } from '../body';

/**
 * This is a page template that only renders the introduction and content modules.
 * Create for legal but can be used for rendering other pages where we don't
 * want to render legacy elements. To use the template, add necessary slug
 * or domainKeys in i18n/json/app/pageContent/[LOCALE].ts
 */
const MinimalPage = ({
  content: {
    article,
    introduction,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<MinimalPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);

  return (
    <Body
      title={primaryHeadline}
      content={({ container, containerModule, contentModule }) => [
        containerModule(
          'introduction-content',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={introduction?.segments ?? []}
              translations={translations}
            />
          </ThemeProvider>,
          !!introduction?.segments,
        ),
        container('content-modules', [
          contentModule(
            'article',
            <ThemeProvider theme={Theme.defaultTheme}>
              <ContentArticle
                modules={article.segments}
                translations={translations}
              />
            </ThemeProvider>,
          ),
        ]),
      ]}
      includeSearch={false}
      {...commonProps}
    />
  );
};

export { MinimalPage };
