import type { PropsWithChildren } from 'react';
import { Button, Ui } from '@silvertours/front-shared';

export type SceneProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export type WizardOverlayProps = PropsWithChildren<{
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  buttonText?: string;
  buttonDisabled?: boolean;
}>;

export const WizardOverlay = ({
  children,
  onClose,
  onConfirm,
  title,
  buttonText,
  buttonDisabled,
}: WizardOverlayProps) => (
  <Ui.Overlay
    title={title}
    titlePosition="left"
    footerContent={
      buttonText && (
        <Button
          disabled={buttonDisabled}
          onClick={onConfirm}
          type="submit"
          id="find"
          size="large"
          fullWidth
          autoFocus={false}
        >
          {buttonText}
        </Button>
      )
    }
    open
    width="full"
    onClose={onClose}
  >
    {children}
  </Ui.Overlay>
);

export type Scene = {
  node: (props: SceneProps) => React.ReactNode;
  goBackOnClose?: boolean;
};
