import { useState, useEffect, useMemo, Fragment } from 'react';
import type { HTMLAttributes } from 'react';
import { Global, useTheme } from '@emotion/react';
import {
  DayPicker,
  DayPickerDefaultProps,
  DayModifiers,
  Matcher,
} from 'react-day-picker';
import { format } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { isAfter } from 'date-fns/isAfter';

import Close from '@engineering/icons/close';
import { CustomMonths, CustomMonthsContext } from './CustomMonths';
import type { DateRange } from '../types';

import { Button, Ui } from '../../../../..';
import {
  GlobalStyles,
  Wrapper,
  StyledHeader,
  StyledFooter,
  Arrow,
} from './DatePicker.styles';

type Props = {
  from?: Date;
  to?: Date;
  dayCountText?: string;
  dateFormat: string;
  scrollToSelectedMonth: boolean;
  vertical: boolean;
  showHeader: boolean;
  showFooter: boolean;
  onDatesChange?: (dateRange: DateRange) => void;
  onClose?: () => void;
  onScrollToBottom?: () => void;
} & Pick<HTMLAttributes<HTMLElement>, 'className'> &
  DayPickerDefaultProps;

const DatePicker = ({
  from,
  to,
  dayCountText,
  scrollToSelectedMonth = false,
  locale,
  dateFormat,
  vertical,
  showHeader,
  showFooter,
  className,
  onDatesChange,
  onScrollToBottom,
  onClose,
  ...props
}: Props) => {
  const theme = useTheme();

  useEffect(() => {
    if (!scrollToSelectedMonth) {
      return;
    }
    const selectedDay = document.querySelector('.rdp-day_selected');
    const selectedMonthElm =
      selectedDay?.parentElement?.parentElement?.parentElement?.parentElement
        ?.parentElement;
    if (selectedMonthElm) {
      selectedMonthElm.scrollIntoView({
        block: 'center',
      });
    }
  }, [scrollToSelectedMonth]);

  const [selectedFrom, setFrom] = useState(from || null);
  const [selectedTo, setTo] = useState(to || null);
  const [hoveredTo, setHoveredTo] = useState(undefined as undefined | Date);

  const isSelectingFirstDay = (date: Date) => {
    const isBeforeFirstDay = selectedFrom && isBefore(date, selectedFrom);
    const isRangeSelected = selectedFrom && selectedTo;
    return !selectedFrom || isBeforeFirstDay || isRangeSelected;
  };

  const handleOnDayClick = (date: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }

    if (isSelectingFirstDay(date)) {
      setFrom(date);
      setTo(null);
      setHoveredTo(undefined);
    } else {
      setTo(date);
      setHoveredTo(date);
      if (typeof onDatesChange !== 'undefined') {
        onDatesChange({ from: selectedFrom, to: date });
      }
    }
  };

  const getHighlightedDays = () => {
    const highlightRules: Matcher[] = [
      (day: Date) => {
        if (selectedFrom && selectedTo) {
          return isAfter(day, selectedFrom) && isBefore(day, selectedTo);
        }
        return false;
      },
    ];

    if (selectedFrom) {
      highlightRules.push(selectedFrom);
    }

    if (selectedFrom && hoveredTo) {
      highlightRules.push({ from: selectedFrom, to: hoveredTo });
    }

    if (selectedFrom && selectedTo) {
      highlightRules.push({ from: selectedFrom, to: selectedTo });
    }

    return highlightRules;
  };

  const getModifiers = () => {
    let modifiers: DayModifiers = {};
    if (selectedFrom) {
      modifiers = { ...modifiers, from: [selectedFrom] };
    }
    if (hoveredTo) {
      modifiers = { ...modifiers, to: hoveredTo };
    } else if (selectedTo) {
      modifiers = { ...modifiers, to: selectedTo };
    }
    return modifiers;
  };

  const handleDayMouseEnter = (day: Date) => {
    if ((from && isBefore(day, from)) || isSelectingFirstDay(day)) {
      return;
    }
    setHoveredTo(day);
  };

  const context = useMemo(
    () => ({ onScrollToBottom, vertical }),
    [onScrollToBottom, vertical],
  );

  const WrapperComponent = showHeader || showFooter ? Wrapper : Fragment;
  const wrapperProps = showHeader || showFooter ? { className } : {};

  return (
    <CustomMonthsContext.Provider value={context}>
      <Global styles={GlobalStyles({ theme, vertical })} />
      <WrapperComponent {...wrapperProps}>
        {showHeader && (
          <StyledHeader>
            {from
              ? format(from, dateFormat, {
                  locale,
                })
              : '---'}
            <Arrow />
            {to
              ? format(to, dateFormat, {
                  locale,
                })
              : '---'}
            <Ui.IconButton
              icon={Close}
              title="close" // @TODO: Add translation
              onClick={onClose}
            />
          </StyledHeader>
        )}
        <DayPicker
          components={{ Months: CustomMonths }}
          disableNavigation={vertical}
          onDayClick={handleOnDayClick}
          onDayMouseEnter={handleDayMouseEnter}
          selected={getHighlightedDays()}
          modifiers={getModifiers()}
          disabled={{ before: new Date() }}
          locale={locale}
          modifiersClassNames={{
            outside: 'rdp-day_outside',
            disabled: 'rdp-day_disabled',
            today: 'rdp-day_today',
            from: 'rdp-day_range_start',
            to: 'rdp-day_range_end',
          }}
          {...props}
        />
        {showFooter && (
          <StyledFooter vertical={vertical}>
            {vertical && dayCountText ? (
              <Button size="small" fullWidth onClick={onClose}>
                {dayCountText}
              </Button>
            ) : (
              dayCountText
            )}
          </StyledFooter>
        )}
      </WrapperComponent>
    </CustomMonthsContext.Provider>
  );
};

export { DatePicker };
