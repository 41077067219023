import { Fragment } from 'react';
import dynamic from 'next/dynamic';

import { BmLogo, CgLogo, Floyt } from './assets';

// These logos should never be lazy-loaded
const LogoMapping = {
  bm: BmLogo,
  cg: CgLogo,
  floyt: Floyt,
};

const otherLogos = [
  'bmInverted',
  'cgInverted',
  'topdeal',
  'trustpilot',
] as const;

type LogoNameType = keyof typeof LogoMapping;
type OtherLogoNameType = (typeof otherLogos)[number];
type ImageNameType = LogoNameType | OtherLogoNameType;

type ImageSVGProps = {
  name: ImageNameType;
  loading?: 'lazy' | 'eager';
};

const ImageSVG = ({ name, loading = 'lazy' }: ImageSVGProps) => {
  const ImageComponent = LogoMapping[name as LogoNameType] || Fragment;

  if (loading === 'eager') {
    return <ImageComponent />;
  }

  const DynamicImageComponent = dynamic(() =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    import(`./assets/${name}`).catch(_err => null),
  );

  return <DynamicImageComponent />;
};

export { ImageSVG };
export type { ImageNameType };
