import { BannerBackground, BannerPromoText } from './MintGradientBanner.styles';

export const MintGradientBanner: React.FC<
  React.PropsWithChildren<{
    text?: string;
    className?: string;
    onClickTracking?: () => void;
  }>
> = ({ children, text, className, onClickTracking }) => (
  <BannerBackground className={className} onClick={onClickTracking}>
    <BannerPromoText id="banner-text" textColor="white">
      {text}
    </BannerPromoText>
    {children}
  </BannerBackground>
);
