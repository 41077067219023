import {
  Popover as HUIPopover,
  PopoverButton as HUIPopoverButton,
  PopoverButtonProps as HUIPopoverButtonProps,
  PopoverPanel as HUIPopoverPanel,
} from '@headlessui/react';
import React, { ReactNode, useRef, useState } from 'react';
import type { ElementType, HTMLAttributes } from 'react';
import { usePopper } from 'react-popper';
import type { Options } from '@popperjs/core';
import { useClickAway } from 'react-use';
import { Arrow, ArrowPositioner, HUIPopoverWrapper } from './Popover.styles';
import { ContentWrapper } from './ContentWrapper';
import { useClickInside } from './useClickInside';

export interface PopoverProps extends HTMLAttributes<HTMLElement> {
  button: HUIPopoverButtonProps<ElementType>;
  position?: 'top' | 'bottom' | 'left' | 'right';
  children?: ReactNode;
  triggerAction?: 'hover' | 'click';
}

const Popover = ({
  button,
  position = 'bottom',
  triggerAction = 'hover',
  children,
}: PopoverProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const options: Options = {
    placement: position,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    strategy: 'absolute',
  };
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    options,
  );
  const [showPopover, setShowPopover] = useState(false);
  const onMouseEnter = () => {
    if (triggerAction === 'hover') {
      setShowPopover(true);
    }
  };

  const onMouseLeave = () => {
    if (triggerAction === 'hover') {
      setShowPopover(false);
    }
  };

  const onClick = () => {
    if (triggerAction === 'click') {
      setShowPopover(!showPopover);
    }
  };

  const onCloseClick = (event?: any) => {
    event?.stopPropagation();
    if (referenceElement && !referenceElement.contains(event?.target)) {
      setShowPopover(false);
    }
  };

  const clickRef = useRef<null | HTMLDivElement>(null);
  useClickAway(clickRef, onCloseClick, ['mouseup']);

  useClickInside(clickRef, (target: EventTarget | null) => {
    if (
      (target && (target as HTMLElement).tagName?.toLowerCase() === 'button') ||
      (target as HTMLElement).parentElement?.tagName.toLowerCase() === 'button'
    ) {
      setShowPopover(false);
    }
  });

  return (
    <HUIPopoverWrapper showing={showPopover} position={position}>
      <HUIPopover
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
      >
        {() => (
          <>
            <HUIPopoverButton
              {...button}
              ref={setReferenceElement}
              onClick={() => onClick()}
            />

            {showPopover && (
              <HUIPopoverPanel
                static
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                <ContentWrapper
                  ref={clickRef}
                  onClose={triggerAction === 'click' ? onCloseClick : undefined}
                >
                  {children}
                </ContentWrapper>
                <ArrowPositioner
                  ref={setArrowElement}
                  style={styles.arrow}
                  position={position}
                >
                  <Arrow position={position} />
                </ArrowPositioner>
              </HUIPopoverPanel>
            )}
          </>
        )}
      </HUIPopover>
    </HUIPopoverWrapper>
  );
};

export { Popover };
