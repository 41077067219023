import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { darken } from 'polished';

import { getLinearGradient } from '../../Theme';
import type { BadgeType, BadgeSize } from './types';

const textColorStylesBadge: Record<BadgeType, 'dark' | 'light'> = {
  default: 'dark',
  dark: 'light',
  light: 'dark',
  orange: 'light',
  green: 'light',
  'orange-gradient': 'light',
  'green-gradient': 'light',
};

const mapColor = (theme: Theme, color: BadgeType = 'default') =>
  ({
    default: darken(0.02, theme.color.grey05!),
    dark: darken(0.599, theme.color.grey05!),
    light: theme.color.white,
    orange: theme.color.secondary,
    green: theme.color.brand,
    'orange-gradient': theme.color.secondary,
    'green-gradient': theme.color.brand,
  })[color];

const Wrapper = styled.span<{
  badgeType: BadgeType;
  badgeSize: BadgeSize;
  hasIcon: boolean;
}>`
  ${({ hasIcon }) =>
    hasIcon
      ? css`
          display: inline-flex;
          flex-wrap: nowrap;
          gap: 0.5rem;
          align-items: center;
        `
      : css`
          display: inline-block;
        `};
  ${({ badgeSize }) =>
    badgeSize === 'small'
      ? css`
          padding: 0.125rem 0.25rem;
          border-radius: 0.25rem;
          font-size: 0.875rem;
        `
      : css`
          padding: 0.25rem 1rem;
          border-radius: 100px;
          font-size: 1rem;
        `};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1.5;
  fill: currentcolor;
  color: ${({ theme, badgeType }) =>
    mapColor(theme, textColorStylesBadge[badgeType])};
  background-color: ${({ theme, badgeType }) => mapColor(theme, badgeType)};
  background-image: ${({ theme, badgeType }) => {
    switch (badgeType) {
      case 'orange-gradient':
        return getLinearGradient(theme.gradient.orange, 'to left');
      case 'green-gradient':
        return getLinearGradient(theme.gradient.green, 'to left');
      default:
        return '';
    }
  }};

  ${({ theme, badgeSize }) =>
    badgeSize !== 'small' &&
    css`
      ${theme.mq('lg')} {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    `}
`;

export { Wrapper };
