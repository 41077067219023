import { Ui } from '@silvertours/front-shared';
import { useState } from 'react';
import { useFormatter } from 'next-intl';
import {
  Container,
  Header,
  Provider,
  StarReview,
  RatingsCount,
  Footer,
  StyledButton,
  CurrentValue,
  MaxValue,
  StyledStar,
  StyledThirdPartyLogo,
} from './ReviewCard.styles';
import { ReviewOverlay } from './ReviewOverlay';
import { ReviewsChart } from './ReviewChart';
import type { RatingChartTranslations, Ratings } from './types';
import { trackShowCustomerReviews } from '../analytics/gtm';

export type ReviewCardProps = {
  hidden?: boolean;
  name: string;
  customerRatingCount: number;
  averageRating: {
    value: number;
    min: number;
    max: number;
  };
  ratings: Ratings;
  searchParams: {
    localization: string;
    supplier: string;
    cityId?: string;
    stateId?: string;
    countryCode?: string;
  };
  translations: {
    overlayLinkText: string;
    searchButtonText: string;
    rating: string;
    ratings: string;
    chart: RatingChartTranslations;
    overlay: {
      title: string;
      rating: string;
      ratings: string;
      showMore: string;
      showLess: string;
    };
  };
  onSearchButtonClick?: () => void;
};

export const ReviewCard = ({
  name,
  customerRatingCount,
  averageRating,
  ratings,
  translations,
  searchParams,
  onSearchButtonClick,
  hidden = false,
}: ReviewCardProps) => {
  const format = useFormatter();
  const [showOverlay, setShowOverlay] = useState(false);
  const showReviewCount = customerRatingCount >= 0;

  const formatValue = (value: number, minDigits = 1, maxDigits = 1) =>
    format.number(value, {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });
  const formattedValue = formatValue(averageRating.value);
  const formattedMaxValue = formatValue(averageRating.max);

  const handleShowOverlay = () => {
    // fires tracking event only if overlay is to be opened
    if (!showOverlay) {
      trackShowCustomerReviews(name);
    }
    setShowOverlay(!showOverlay);
  };

  const handleHideOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <Container hidden={hidden}>
      <Header>
        <Provider title={name}>{name}</Provider>
        <StyledThirdPartyLogo name={name} withPadding="vertical" />
        <StarReview
          value={formattedValue}
          title={`${formattedValue}/${formattedMaxValue}`}
        >
          <StyledStar />
          <CurrentValue aria-hidden="true">{formattedValue}</CurrentValue>
          <MaxValue aria-hidden="true">/{formattedMaxValue}</MaxValue>
        </StarReview>
        {showReviewCount && (
          <RatingsCount>
            {customerRatingCount > 1
              ? `${customerRatingCount} ${translations.ratings}`
              : `${customerRatingCount} ${translations.rating}`}
          </RatingsCount>
        )}
      </Header>
      <ReviewsChart translations={translations.chart} ratings={ratings} />
      <Footer>
        <Ui.TextLink
          text={translations.overlayLinkText}
          variant="text"
          as="button"
          onClick={handleShowOverlay}
        >
          {showOverlay && (
            <ReviewOverlay
              localization={searchParams.localization}
              supplier={searchParams.supplier}
              cityId={searchParams.cityId}
              stateId={searchParams.stateId}
              countryCode={searchParams.countryCode}
              onClose={handleHideOverlay}
              translations={{
                ...translations.overlay,
                chart: translations.chart,
              }}
            />
          )}
        </Ui.TextLink>
        {onSearchButtonClick && (
          <StyledButton onClick={onSearchButtonClick} loading>
            {translations.searchButtonText}
          </StyledButton>
        )}
      </Footer>
    </Container>
  );
};
