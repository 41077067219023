import styled from '@emotion/styled';
import { css, SerializedStyles, Theme as ThemeType } from '@emotion/react';

import { Theme, Styles } from '@silvertours/front-shared';
import { LayoutFlow, BackgroundStyle } from './rule';
import { SpacingDirection } from '../rule';

type ContainerProps = {
  isLarge?: boolean;
  layoutFlow?: LayoutFlow;
  backgroundStyle?: BackgroundStyle;
};

type WaveContainerProps = ContainerProps & {
  spacingDirection?: SpacingDirection;
};

const getLayoutFlow = (layoutFlow: LayoutFlow) =>
  ({
    top: css`
      display: flex;
      flex-direction: column-reverse;
    `,
    bottom: css`
      display: flex;
      flex-direction: column;
    `,
    left: css`
      display: flex;
      flex-direction: column;

      ${Theme.mq('sm')} {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, auto));
      }
    `,
    right: css`
      display: flex;
      flex-direction: column;

      ${Theme.mq('sm')} {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, auto));
      }
    `,
  })[layoutFlow];

const getBackgroundColor = (
  theme: ThemeType,
  style: BackgroundStyle,
): SerializedStyles | string =>
  ({
    default: '',
    gray: css`
      background-color: ${theme.color.grey05};
    `,
    green: '',
    mint: '',
    orange: '',
    brand: '',
  })[style];

const getSpacingDirection = (
  spacingDirection: SpacingDirection,
): SerializedStyles | string =>
  ({
    top: css`
      padding-top: 4rem;
      padding-bottom: 0;

      ${Theme.mq('lg')} {
        padding-top: 6rem;
      }
    `,
    bottom: css`
      padding-top: 0;
      padding-bottom: 4rem;

      ${Theme.mq('lg')} {
        padding-bottom: 6rem;
      }
    `,
    both: '',
    neither: '',
  })[spacingDirection];

const Container = styled.section<ContainerProps>`
  ${Styles.Global.layoutContainerContent}

  gap: 2.5rem;

  ${({ theme }) => theme.mq('lg')} {
    gap: 3rem;
  }

  ${({ isLarge, layoutFlow }) =>
    isLarge && layoutFlow && getLayoutFlow(layoutFlow)};

  ${({ theme, backgroundStyle }) =>
    backgroundStyle && getBackgroundColor(theme, backgroundStyle)};

  ${({ isLarge, layoutFlow = '' }) =>
    isLarge &&
    !['left', 'right'].includes(layoutFlow) &&
    css`
      padding-top: 4rem;
      padding-bottom: 4rem;

      ${Theme.mq('xl')} {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }
    `};
`;

const WaveContainer = styled(Container)<WaveContainerProps>`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  ${({ spacingDirection }) =>
    spacingDirection && getSpacingDirection(spacingDirection)};
`;

export { Container, WaveContainer };
