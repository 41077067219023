import {
  MARKS,
  BLOCKS,
  INLINES,
  Block,
  Inline,
} from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import type { ReactNode } from 'react';

import { TextLink } from '../../TextLink';
import {
  BoldWrapper,
  ItalicWrapper,
  UnderlineWrapper,
} from './ContentfulRichText.styles';
import {
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from '../../../Text';

export type RichTextContent = Block | Inline;

export const options: Options = {
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <BoldWrapper>{text}</BoldWrapper>,
    [MARKS.ITALIC]: (text: ReactNode) => <ItalicWrapper>{text}</ItalicWrapper>,
    [MARKS.UNDERLINE]: (text: ReactNode) => (
      <UnderlineWrapper>{text}</UnderlineWrapper>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }: Block | Inline, children: ReactNode) => (
      <TextLink
        href={data.uri}
        target="_blank"
        rel="noopener noreferrer"
        variant="text"
      >
        {children}
      </TextLink>
    ),
    [BLOCKS.HEADING_2]: (_: Block | Inline, children: ReactNode) => (
      <Heading2 textColor="current">{children}</Heading2>
    ),
    [BLOCKS.HEADING_3]: (_: Block | Inline, children: ReactNode) => (
      <Heading3 textColor="current">{children}</Heading3>
    ),
    [BLOCKS.HEADING_4]: (_: Block | Inline, children: ReactNode) => (
      <Heading4 textColor="current">{children}</Heading4>
    ),
    [BLOCKS.HEADING_5]: (_: Block | Inline, children: ReactNode) => (
      <Heading5 textColor="current">{children}</Heading5>
    ),
    [BLOCKS.HEADING_6]: (_: Block | Inline, children: ReactNode) => (
      <Heading6 textColor="current">{children}</Heading6>
    ),
    [BLOCKS.OL_LIST]: (_: Block | Inline, children: ReactNode) => (
      <ol>{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (_: Block | Inline, children: ReactNode) => (
      <ul>{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (_: Block | Inline, children: ReactNode) => (
      <li>{children}</li>
    ),
    [BLOCKS.TABLE]: (_: Block | Inline, children: ReactNode) => (
      <table>
        <tbody>{children}</tbody>
      </table>
    ),
    [BLOCKS.TABLE_ROW]: (_: Block | Inline, children: ReactNode) => (
      <tr>{children}</tr>
    ),
    [BLOCKS.TABLE_CELL]: (_: Block | Inline, children: ReactNode) => (
      <td>{children}</td>
    ),
    [BLOCKS.TABLE_HEADER_CELL]: (_: Block | Inline, children: ReactNode) => (
      <th>{children}</th>
    ),
  },
};
