import React, { ReactElement } from 'react';
import Link from 'next/link';

import { LayoutLegacy } from '@silvertours/front-legacy-entities';
import { UiLegacy } from '@silvertours/front-legacy-shared';

import { useLocale, useTranslations } from 'next-intl';
import { FooterBaseContent, FooterList, FooterContainer } from './Page.styles';

type LinkProps = {
  text: string;
  href: string;
  rel?: string;
  target?: '_blank';
  external?: boolean;
};

type PageFooterProps = {
  showLogo?: boolean;
};

type LinkWrapperProps = {
  item: LinkProps;
  children: ReactElement;
};

const footerLinksDe = [
  'press',
  'company',
  'partners',
  'privacy',
  'compliance',
  'cookies',
  'faq',
  'gtc',
  'imprint',
  'newsletter',
  'job',
] as const;

const footerLinksFr = [
  'privacy',
  'gtc',
  'compliance',
  'cookies',
  'company',
  'info',
  'press',
  'partners',
  'faq',
  'support',
] as const;

const LinkWrapper = ({ item, children }: LinkWrapperProps) => {
  const external =
    Object.prototype.hasOwnProperty.call(item, 'external') && item.external;
  // links marked as 'external' use the standard anchor tag
  // instead of the Link component provided by next.js

  if (external) {
    return children;
  }

  return (
    <Link passHref href={item.href} legacyBehavior>
      {children}
    </Link>
  );
};

const PageFooter = ({ showLogo = true }: PageFooterProps) => {
  const locale = useLocale();
  const footerLinksEntries = locale === 'de-DE' ? footerLinksDe : footerLinksFr;
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  const t = useTranslations('features.navigationLegacy.footer');
  const links = footerLinksEntries.map(item => ({
    text: t.raw(`links.${item}.text`),
    href: t.raw(`links.${item}.href`),
    target: t.raw(`links.${item}.target`),
    rel: t.raw(`links.${item}.rel`),
    external: t.raw(`links.${item}.external`) === 'true',
  }));
  const logo = t('logo') as UiLegacy.ImageNameType;

  return (
    <LayoutLegacy.FooterSegment id="page-footer">
      <FooterContainer>
        {/* TODO: add translation for this aria-label */}
        <button type="button" onClick={scrollToTop} aria-label="back to top">
          <UiLegacy.SvgIcon name="arrowUp" />
        </button>

        <FooterBaseContent>
          <FooterList role="menu">
            {links.map(
              item =>
                item.text &&
                item.text !== '' && (
                  <li key={`footer-${item.href}`} role="presentation">
                    <LinkWrapper item={item}>
                      <a
                        href={item.href}
                        rel={item.rel}
                        target={item.target}
                        role="menuitem"
                      >
                        {item.text}
                      </a>
                    </LinkWrapper>
                  </li>
                ),
            )}
          </FooterList>
          {showLogo && <UiLegacy.ImageSVG name={logo} />}
        </FooterBaseContent>
      </FooterContainer>
    </LayoutLegacy.FooterSegment>
  );
};

export { PageFooter };
