import styled from '@emotion/styled';
import { TextStyleRules, RichTextStyle } from '../rules';

type ContentfulRichTextWrapperProps = {
  textStyle?: TextStyleRules;
};

const getTextStyleRules = (rules?: TextStyleRules) => {
  const hasSpacedParagraphs =
    typeof rules?.hasSpacedParagraphs === 'undefined'
      ? true
      : rules.hasSpacedParagraphs;
  const style =
    typeof rules?.style === 'undefined' ? RichTextStyle.Primary : rules.style;

  return { hasSpacedParagraphs, style };
};

const setColor = (rules?: TextStyleRules) => {
  const { style } = getTextStyleRules(rules);
  return style === RichTextStyle.Muted ? 'grey' : '';
};

const setTextColor = (rules?: TextStyleRules) => {
  const { style } = getTextStyleRules(rules);
  return style === RichTextStyle.Alternative ? 'white' : '';
};

const setMarginTop = (rules?: TextStyleRules) => {
  const { hasSpacedParagraphs } = getTextStyleRules(rules);
  return hasSpacedParagraphs ? '1.5rem' : '';
};

const ContentfulRichTextWrapper = styled.article<ContentfulRichTextWrapperProps>`
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 1.588em;

  & > p:not(:first-of-type) {
    margin-top: ${p => setMarginTop(p.textStyle)};
  }

  & > p,
  & > p > a,
  & > ol > li,
  & > ul > li {
    color: ${p => setTextColor(p.textStyle)};
  }

  color: ${p => setColor(p.textStyle)};

  a {
    color: ${({ theme }) => theme.color.brand};
  }

  ul {
    list-style-type: disc;
    padding-left: 1.25rem;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.25rem;
  }

  p {
    white-space: pre-wrap;
  }

  & > p + h2 {
    margin-top: 3rem;
  }

  & > table {
    width: 100%;
    border: none;
    margin: 1rem 0 3rem;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5rem;

    td {
      padding: 0.625rem;
      border: none;
    }

    th {
      margin: 0.375rem;
      padding: 0.375rem;
      text-align: left;
    }

    tbody > tr:first-of-type td,
    tr th {
      background-color: ${({ theme }) => theme.color.brand};
      color: ${({ theme }) => theme.color.white};

      a {
        color: inherit;
      }
    }

    thead + tbody > tr:first-of-type td {
      background-color: ${({ theme }) => theme.color.grey05};
      color: ${({ theme }) => theme.color.text};
    }

    tr:nth-of-type(odd),
    tr:nth-of-type(odd) td {
      background-color: ${({ theme }) => theme.color.grey05};
    }

    tr:nth-of-type(even),
    tr:nth-of-type(even) td {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`;

const BoldWrapper = styled.strong`
  font-weight: bold;
`;

const ItalicWrapper = styled.i`
  font-style: italic;
`;

const UnderlineWrapper = styled.span`
  text-decoration: underline;
`;

export {
  BoldWrapper,
  ItalicWrapper,
  ContentfulRichTextWrapper,
  UnderlineWrapper,
};
