import styled from '@emotion/styled';
import { Color } from '../../../../Theme';

const StyledLabel = styled.label`
  display: inline-flex;
  column-gap: 0.5rem;
  cursor: pointer;
  user-select: none;
`;

// screen reader only
const StyledCheckbox = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

type CheckboxWrapperProps = {
  isChecked?: boolean;
};

const CheckboxWrapper = styled.span<CheckboxWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${Color.white};
  border: 1px solid ${props => (props.isChecked ? Color.brand : Color.grey03)};
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.1875rem;
  outline: 0;

  svg {
    fill: ${props => (props.isChecked ? Color.brand : 'inherit')};
  }
`;

export { StyledLabel, StyledCheckbox, CheckboxWrapper };
