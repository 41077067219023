import styled from '@emotion/styled';
import { Header } from '@silvertours/front-features';

const StyledHeader = styled(Header)`
  background-color: ${({ theme }) => theme.color.white};
  padding: 0 ${({ theme }) => theme.spacing[50]};
`;

const MarketPickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export { StyledHeader, MarketPickerWrapper };
