import styled from '@emotion/styled';
import { Theme } from '@silvertours/front-shared';

const UspContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UspItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${Theme.mq('lg')} {
    flex-direction: row;
  }
`;

const UspItem = styled.div`
  display: flex;
  flex: 1;
  margin: 0 0 1.5rem;

  ${Theme.mq('lg')} {
    margin: 0.5rem 0;
  }
`;

const UspTitleContainer = styled.div`
  margin-bottom: 1rem;
`;

const UspTitle = styled.div`
  color: ${Theme.Color.text};
  margin: 0 0 0.5rem;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.125;

  a {
    color: inherit;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
  margin-top: 0.5rem;
`;

const IconWrapper = styled.div`
  margin-right: 0.3125rem;
  fill: ${({ theme }) => theme.color.brand};

  svg {
    width: 45px;
    height: 45px;
  }
`;

export {
  IconWrapper,
  RatingContainer,
  UspContainer,
  UspItem,
  UspItems,
  UspTitleContainer,
  UspTitle,
};
