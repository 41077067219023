import { LocaleValue } from '@silvertours/common-landingpages-view';
import { String } from '@silvertours/front-shared';
import {
  CardContainer,
  Label,
  Line,
  MonthContainer,
  PriceWrapper,
  NoDataTextWrapper,
  ForbiddenIcon,
  PiggyBankIcon,
  ImageWrapper,
  StyledButton,
  TextBlock,
} from './Graph.styles';
import { useLocale } from '../Runtime';

export const CheapestMonthIndicator = ({
  selected,
  cheapestMonthLabel,
}: {
  selected?: boolean;
  cheapestMonthLabel?: string;
}) => (
  <>
    <ImageWrapper selected={selected}>
      <PiggyBankIcon />
    </ImageWrapper>
    <Label>{cheapestMonthLabel}</Label>
  </>
);

type Props = {
  date: string;
  priceMedian: number;
  priceMax: number;
  priceMin: number;
  cheapestMonth?: boolean;
  onClick: () => void;
  triggerSearch: (cityCode: string, cityName: string, date: string) => void;
  cityCode: string;
  cityName: string;
  translations?: {
    averagePrice: string;
    buttonText: string;
    priceRange: string;
    cheapestMonthLabel: string;
    noDataText: string;
  };
};

const getMonthName = (date: string, locale: LocaleValue) => {
  const dateObj = new Date(Date.parse(date));
  const month = new Intl.DateTimeFormat(locale, { month: 'long' }).format(
    dateObj,
  );
  return month[0].toUpperCase() + month.slice(1);
};

export const Card = ({
  date,
  priceMedian,
  priceMax,
  priceMin,
  cheapestMonth = false,
  onClick,
  triggerSearch,
  cityCode,
  cityName,
  translations,
}: Props) => {
  const { locale, language } = useLocale();
  const pricesAreAvailable = priceMedian !== 0;
  const { averagePrice, buttonText, priceRange, cheapestMonthLabel } =
    translations || {};

  const handleButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    triggerSearch(cityCode, cityName, date);
    event.stopPropagation();
    event.preventDefault();
  };

  const formattedPrice = {
    min: String.formatPrice(priceMin, 'EUR', language),
    max: String.formatPrice(priceMax, 'EUR', language),
    median: String.formatPrice(priceMedian, 'EUR', language),
  };

  return (
    <CardContainer onClick={onClick} pricesAreAvailable={pricesAreAvailable}>
      {cheapestMonth && (
        <CheapestMonthIndicator
          selected
          cheapestMonthLabel={cheapestMonthLabel}
        />
      )}
      <TextBlock>
        <MonthContainer>{getMonthName(date, locale)}</MonthContainer>
        <Line />
        {pricesAreAvailable ? (
          <>
            <div>{averagePrice}</div>
            <PriceWrapper>{formattedPrice.median}</PriceWrapper>
            <div>{priceRange}</div>
            <PriceWrapper>
              {formattedPrice.min} – {formattedPrice.max}
            </PriceWrapper>
          </>
        ) : (
          <NoDataTextWrapper textColor="grey" selected>
            <ForbiddenIcon />
            <span>{(translations || {}).noDataText}</span>
          </NoDataTextWrapper>
        )}
      </TextBlock>
      <StyledButton
        onClick={handleButtonClick}
        variant="primary"
        size="small"
        fullWidth
      >
        {buttonText}
      </StyledButton>
    </CardContainer>
  );
};
