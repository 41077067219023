import { useEffect, useState, MouseEvent, useCallback } from 'react';
import { useTranslations } from 'next-intl';
import { format } from 'date-fns/format';

import { Theme } from '../../../..';

/**
 * @todo: When this component moves layers, these imports shoud be updated
 * to their package aliases (i.e. '@silvertours/front-legacy-shared').
 */
import { useLocale } from '../../../I18n';
import { dateFormats } from '../../../../strings';

import { ErrorMessage } from '../../../../Ui/ErrorMessage';
import type { DateRange } from './types';
import { Button } from './DatePicker';
import { TimePicker } from './TimePicker';
import { addTimeToDate } from './DatePicker/Calendar';

import { Backdrop, StyledCalendar, TimeRange } from './DateRangePicker.styles';

const HOUR_IN_MS = 1000 * 60 * 60;

type DateRangePickerProps = {
  from: Date;
  to: Date;
  open?: boolean;
  vertical?: boolean;
  size?: 'small';
  error: string;
  onDatesChange?: (dateRange: DateRange) => void;
};

/** @todo: Move to /entities layer - this is too sophisticated for /shared */
const DateRangePicker = ({
  from,
  to,
  open = false,
  vertical = false,
  size,
  error,
  onDatesChange,
}: DateRangePickerProps) => {
  /**
   * @todo:
   * The translation key should be aligned with the current layer/slice (i.e.
   * 'entities.dateLegacy.dateRangePicker' - once the component has been moved
   * to the /entities layer)
   * */
  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const { language } = useLocale();

  const [isCalendarVisible, setCalendarVisibility] = useState(open);
  const [selectedDates, setSelectedDates] = useState<DateRange>({
    from,
    to,
  });

  const isMobileTouchDevice = Theme.useIsMobileTouchDevice();

  useEffect(() => {
    setSelectedDates({ from, to });
  }, [from, to]);

  const localDateFormats = dateFormats[language as keyof typeof dateFormats];

  const handleBackdropClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setCalendarVisibility(false);
  };

  const handleDatesChange = (dateRange: DateRange) => {
    if (typeof onDatesChange !== 'undefined') {
      onDatesChange(dateRange);
    }
    setCalendarVisibility(isMobileTouchDevice);
  };

  const handleTimeChange = (time: string, type: 'from' | 'to') => {
    const [hours, minutes] = time.split(':');
    if (selectedDates[type]) {
      const dateWithTime = addTimeToDate(
        selectedDates[type]!,
        Number(hours),
        Number(minutes),
      );

      const newDateRange = { ...selectedDates, [type]: dateWithTime };
      if (typeof onDatesChange !== 'undefined') {
        onDatesChange(newDateRange);
      }
      setSelectedDates(newDateRange);
    }
  };

  const handleButtonClick = useCallback(() => {
    setCalendarVisibility(!isCalendarVisible);
  }, [isCalendarVisible]);

  const getDays = () => {
    if (selectedDates.from && selectedDates.to) {
      // Inspired by https://youmightnotneed.com/date-fns#differenceInDays,
      // because differenceInHours() from date-fns always rounds which is not what we want
      const days =
        ((selectedDates.to as any) - (selectedDates.from as any)) /
        HOUR_IN_MS /
        24;
      return Number.isInteger(days) ? days : Math.ceil(days);
    }
    return 1;
  };

  const dayCountText = t('dayCount', { days: getDays() });

  return (
    <>
      {isCalendarVisible && (
        <Backdrop role="presentation" onClick={handleBackdropClick} />
      )}

      <Button
        title={t('datePicker')}
        from={from}
        to={to}
        size={size}
        onClick={handleButtonClick}
      />

      <ErrorMessage>{error}</ErrorMessage>

      {isCalendarVisible && (
        <StyledCalendar
          from={from}
          to={to}
          dayCountText={dayCountText}
          vertical={vertical}
          showHeader={vertical}
          showFooter
          onChange={handleDatesChange}
          onClose={() => {
            setCalendarVisibility(false);
          }}
        />
      )}

      <TimeRange>
        <TimePicker
          id="depTime"
          type="from"
          label={t('depTime.label')}
          value={
            selectedDates.from
              ? format(selectedDates.from, localDateFormats.timeGeneric)
              : undefined
          }
          onChange={handleTimeChange}
        />
        <TimePicker
          id="destTime"
          type="to"
          label={t('destTime.label')}
          value={
            selectedDates.to
              ? format(selectedDates.to, localDateFormats.timeGeneric)
              : undefined
          }
          onChange={handleTimeChange}
        />
      </TimeRange>
    </>
  );
};

export { DateRangePicker };
