import styled from '@emotion/styled';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;

  a {
    width: 43%;
    z-index: 1;

    svg {
      width: 100%;
    }
  }
`;

export { ButtonContainer };
