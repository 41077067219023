import type { ComponentProps } from 'react';
import styled from '@emotion/styled';
import Edit from '@engineering/icons/edit';
import Info from '@engineering/icons/info';
import { Ui } from '@silvertours/front-shared';

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.default};
  margin-top: 1.5rem;
`;

export const DropdownWrapper = styled(InputWrapper)`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const InputButtonLabel = styled.div`
  position: absolute;
  top: -0.5625rem;
  left: 0.75rem;
  padding: 0 ${({ theme }) => theme.spacing[10]};
  z-index: 2;
  color: ${({ theme }) => theme.color.grey01};
  background-color: ${({ theme }) => theme.color.white};
  font-size: 0.75rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InputButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  border: 1px solid ${({ theme }) => theme.color.grey03};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  width: 100%;
  font-family: inherit;
  font-size: 1rem;
  line-height: 3.375;
  text-align: left;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.color.grey01};
  }

  &:focus-visible {
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }
`;

export const StyledDropdown = styled(Ui.Dropdown)`
  min-width: 8em;
  height: 3.375rem;
`;

export const Section = styled.div<{ multiColumn?: boolean }>`
  display: grid;
  /* stylelint-disable named-grid-areas-no-invalid */
  grid-template-areas: ${({ multiColumn }) =>
    multiColumn
      ? '"label_a label_b ." "value_a value_b edit" "error error ."'
      : '"label_a ." "value_a edit"'};
  /* stylelint-enable named-grid-areas-no-invalid */
  grid-template-columns: ${({ multiColumn }) =>
    multiColumn ? 'max-content 1fr max-content' : '1fr max-content'};
  grid-gap: 0.25rem 2rem;
  margin-bottom: ${({ theme }) => theme.spacing[50]};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.spacing[50]};
  }
`;

export const Label = styled.div<{ $isInvalid?: boolean }>`
  grid-area: label_a;
  color: ${({ theme, $isInvalid }) =>
    $isInvalid ? theme.color.error : theme.color.grey02};

  & ~ & {
    grid-area: label_b;
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  grid-area: value_a;
  font-size: ${({ theme }) => theme.fontSize.sm.size};
  font-weight: 600;

  & ~ & {
    grid-area: value_b;
  }
`;

const RawEditButton = (
  args: Omit<ComponentProps<typeof Ui.IconButton>, 'icon'>,
) => <Ui.IconButton icon={Edit} {...args} />;

export const EditButton = styled(RawEditButton)`
  grid-area: edit;
  justify-self: end;
  fill: ${({ theme }) => theme.color.brand};
`;

export const AgeSelect = styled.select`
  grid-area: edit;
  opacity: 0;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  fill: currentcolor;
`;

export const InfoIcon = styled(Info)`
  fill: ${({ theme }) => theme.color.brand};
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  cursor: pointer;
`;

export const ErrorMessage = styled(Ui.ErrorMessage)`
  grid-area: error;
  margin: 0;
`;
