import { Image } from '@silvertours/common-landingpages-view';
import {
  ImageWithFocalPoint,
  MediaLegacy,
} from '@silvertours/front-legacy-shared';
import { useCallback, useState } from 'react';
import { useContentModule } from '@silvertours/front-entities';
import { SearchForm } from '../SearchForm';
import { MobileSearchContainer, TrustpilotWidget } from './NewSearch.styles';
import { SearchWizard, useSearchWizard } from '../SearchWizard';
import { matchError, useSubmitHandler } from '../SearchFormLegacy';
import { useSearchContext } from '../SearchFormLegacy/context';
import { isFormError } from '../SearchFormLegacy/context/types';

type Props = {
  image: MediaLegacy.ImageProps | Image;
  includeSearch?: boolean;
};

const NewSearchContainer = ({ image, includeSearch }: Props) => {
  const [showWizard, setShowWizard] = useState(false);

  const action = () => {
    setShowWizard(true);
  };

  const { state, setError } = useSearchContext();
  const submitHandler = useSubmitHandler();
  const { getTrustpilotData } = useContentModule();
  const trustpilotProps = getTrustpilotData();

  const onError = useCallback((error: any) => {
    const { type: kind, message } = matchError(error);

    if (isFormError(kind)) {
      setError({ kind, message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { scenes } = useSearchWizard();

  if (showWizard) {
    return (
      <SearchWizard
        scenes={scenes}
        onClose={() => setShowWizard(false)}
        onConfirm={() => {
          submitHandler(state, '', () => {}, onError);
        }}
      />
    );
  }

  return (
    <MobileSearchContainer>
      <ImageWithFocalPoint {...image} id="stage-image-mobile" priority />
      {includeSearch && <SearchForm searchAction={action} />}
      {trustpilotProps && <TrustpilotWidget {...trustpilotProps} />}
    </MobileSearchContainer>
  );
};

export { NewSearchContainer };
