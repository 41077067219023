import { css, Global } from '@emotion/react';
import { Theme } from '../../Theme';

interface CSSResetProps {
  theme: Theme;
}

export const CSSReset: React.FC<CSSResetProps> = ({ theme }) => (
  <Global
    styles={css`
      * {
        box-sizing: border-box;
      }

      html {
        font-family: ${theme.fontFamily.default};
      }

      body {
        margin: 0;
        padding: 0;
        font-size: ${theme.fontSize.xs.size};
        line-height: 1.25rem;

        & > div {
          overflow-x: hidden;

          &#storybook-root {
            overflow-x: unset;
          }
        }
      }

      p {
        margin: 0;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;

        &::-ms-expand {
          display: none;
        }

        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 #000;
        }

        &:focus {
          outline: 0;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      // For styling Google Maps InfoWindow component, which doesn't allow for custom styling
      // Component @ frontend/entities/src/Map/CustomMarker/CustomMarker.tsx
      .gm-style-iw.gm-style-iw-c {
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        & > button {
          display: none !important;
        }
      }
      .gm-style-iw-t > .gm-style-iw-tc {
        top: -5px;
      }
      .gm-style-iw-d {
        height: 99px;
        max-width: 380px;
        background-color: transparent;
        overflow: hidden !important;
        & > div {
          height: 99px;
        }
      }
    `}
  />
);
