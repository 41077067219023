const viewBox = '0 0 515.6 144.2';
const width = '175px';
const height = '30px';

const primaryLogoColor = '#005e34';

const Floyt = () => (
  <svg viewBox={viewBox} width={width} height={height}>
    <path
      fill={primaryLogoColor}
      d="M505.8,22.8c0-6.7-5.5-12.2-12.2-12.2h-99.9c-3.8,0-7.3,1.7-9.7,4.7c-1.1,1.1-30.2,42-30.2,42
                c-1,1.4-3,1.7-4.4,0.7c-0.3-0.2-0.5-0.4-0.7-0.7c0,0-28.6-39.7-29.3-40.5c-2.6-3.8-6-6.2-10.5-6.2h-70.5c-60.6,0-75,55.3-60.4,86.8
                c0.5,1.2,1.7,1.9,2.9,1.9h25.8c1.1,0,2-0.9,2-2c0-0.5-0.2-0.9-0.5-1.3c-16.4-16.3-11.1-61,27.3-61h61.1c1.5,0,2.9,0.7,3.8,1.9
                l36.1,47.6c0.8,1,1.2,2.3,1.2,3.6v31.6c0,7.4,6,13.4,13.4,13.5l0,0c7.4,0,13.5-6,13.5-13.4c0,0,0,0,0,0V88
                c-0.2-1.3,0.2-2.5,0.9-3.6c0.1-0.2,35.3-47.5,35.3-47.5c0.9-1.2,2.3-1.9,3.8-1.9h32.8c1.8,0,3.2,1.4,3.2,3.2l0,81.3
                c0,7.4,5.9,13.5,13.4,13.6l0.1-0.1c7.4,0,13.5-6,13.5-13.4l-0.1-81.3c0-1.8,1.4-3.2,3.2-3.2h0h23.1C500.3,35,505.8,29.5,505.8,22.8
                C505.8,22.8,505.8,22.8,505.8,22.8L505.8,22.8z"
    />
    <path
      fill={primaryLogoColor}
      d="M290.4,44.9h-25.9c-1.1,0-2,0.9-2,2c0,0.4,0.2,0.9,0.4,1.2c16.8,23.6,6.8,60.8-29.6,60.8h-96.3
                c-1.8,0-3.2-1.4-3.2-3.2c0,0,0,0,0,0V24.1c0-4.1-1.8-8-5-10.5c-2.2-1.9-5.1-2.9-8-2.9H19.4C12,10.7,6,16.7,6,24.1v95.5
                c0,7.4,6,13.5,13.5,13.5c0,0,0,0,0,0l0,0c7.4,0,13.4-6,13.4-13.5V88.8c0-1.8,1.4-3.2,3.2-3.2l0,0h28.8c6.7,0,12.2-5.5,12.2-12.2
                c0,0,0,0,0,0l0,0c0-6.8-5.5-12.2-12.2-12.2c0,0,0,0,0,0H36.1c-1.7,0-3.2-1.4-3.2-3.2V38.3c0-1.8,1.4-3.2,3.2-3.2l0,0h67.4
                c1.8,0,3.2,1.4,3.2,3.2l0,0v81.2c0,7.4,6,13.5,13.4,13.5c0,0,0,0,0,0c0,0,113.5,0,113.8-0.1c49.6,0,74.9-43.9,59.3-86.1
                C292.8,45.7,291.7,44.9,290.4,44.9z"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default Floyt;
