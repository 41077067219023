import { LanguageCode } from '@silvertours/common-landingpages-view';
import { Theme, UiLegacy } from '@silvertours/front-legacy-shared';
import { CookieConsent, useConsent } from '@silvertours/front-shared';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { useCallback, useState } from 'react';

const SUPPORTED_LANGUAGES = ['de', 'fr'];
const STORAGE_NAME = 'appPromotionBannerShownOn';
const PARAMETER_NAME = 'disableAppPromotionBanner';

export const useShowAppBanner = (language: LanguageCode) => {
  const { isShowing, toggle } = UiLegacy.useForegroundLayer();
  const consent = useConsent();
  const [isAlreadyShown, setIsAlreadyShown] = useState(false);

  const isLanguageAllowed = SUPPORTED_LANGUAGES.includes(language);

  const isMobileTouchDevice = Theme.useIsMobileTouchDevice();

  const preConditionsMet = isLanguageAllowed && isMobileTouchDevice;
  if (preConditionsMet) {
    let appPromotionBannerShownOn = null;
    if (consent === CookieConsent.Enabled) {
      appPromotionBannerShownOn = window.localStorage.getItem(STORAGE_NAME);
    }

    const startOfNextDay = appPromotionBannerShownOn
      ? addDays(startOfDay(parseISO(appPromotionBannerShownOn)), 1)
      : startOfDay(new Date());

    const shouldShowBanner = isBefore(startOfNextDay, Date.now());
    const shouldHideBanner = Boolean(
      new URLSearchParams(window.location.search).get(PARAMETER_NAME),
    );

    if (
      // cookie consent granted and last seen was yesterday
      ((consent === CookieConsent.Enabled && shouldShowBanner) ||
        // OR cookie consent denied and banner was not already rendered
        (consent === CookieConsent.Disabled && !isAlreadyShown)) &&
      !shouldHideBanner &&
      !isShowing
    ) {
      toggle();
      setIsAlreadyShown(true);
    }
  }

  const onClose = useCallback(() => {
    toggle();
    setIsAlreadyShown(true);
    if (consent === CookieConsent.Enabled) {
      window.localStorage.setItem(STORAGE_NAME, formatISO(Date.now()));
    }
  }, [consent, toggle]);

  return { isShowing, onClose };
};
