import React, { useCallback } from 'react';
import type { SelectHTMLAttributes, ChangeEvent } from 'react';
import { String, TimePickerOverlay } from '@silvertours/front-shared';
import { analytics, Runtime, useSiteInfo } from '@silvertours/front-entities';
import { format } from 'date-fns/format';
import { useTranslations } from 'next-intl';
import ArrowBack from '@engineering/icons/arrow-back';
import ArrowForward from '@engineering/icons/arrow-forward';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import concat from 'lodash/concat';
import range from 'lodash/range';
import { DEFAULT_AGE, useSearchContext } from '../SearchFormLegacy/context';
import { SceneProps, WizardOverlay } from './common';
import { getTime, setTime } from './datetime';
import { CalendarScene } from './CalendarScene';
import { SelectStationScene } from './SelectStationScene';
import { ErrorMessages } from './ErrorMessages';
import {
  AgeSelect,
  EditButton,
  ErrorMessage,
  IconWrapper,
  InfoIcon,
  Label,
  Section,
  Value,
} from './SearchWizard.styles';

const useCustomerAgeValue = (age: number) => {
  const t = useTranslations('features.stage.search.summary');
  if (age === DEFAULT_AGE) {
    return t('age.defaultAge');
  }
  return age.toString();
};

type SubSceneKey =
  | 'pickupLocation'
  | 'returnLocation'
  | 'calendar'
  | 'timePicker';

type Props = SceneProps & {
  onlyLocations?: boolean;
};

const AgeDropdown = (props: SelectHTMLAttributes<HTMLSelectElement>) => {
  const t = useTranslations('features.stage.search.summary');

  const ageDropdownData = concat(
    range(18, 26).map(item => ({
      label: item.toString(),
      value: item.toString(),
    })),
    {
      label: t('age.defaultAge'),
      value: DEFAULT_AGE.toString(),
    },
    range(66, 100).map(item => ({
      label: item.toString(),
      value: item.toString(),
    })),
  );

  return (
    <AgeSelect {...props}>
      {ageDropdownData.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </AgeSelect>
  );
};

const SummaryScene = ({ onlyLocations, ...sceneProps }: Props) => {
  const { state, setDate, setCustomerAge } = useSearchContext();
  if (!state) {
    throw new Error('No search state found');
  }

  const locale = Runtime.useLocale();
  const { dateLong, timeGeneric } = String.dateFormats[locale.language];
  const customerAge = useCustomerAgeValue(state.customerAge);
  const [activeScene, setActiveScene] = React.useState<
    SubSceneKey | undefined
  >();
  const unsetActiveScene = useCallback(
    () => setActiveScene(undefined),
    [setActiveScene],
  );
  const t = useTranslations('features.stage.search');

  const {
    settings: { featureToggles },
  } = useSiteInfo();

  const version = featureToggles.useNewDesignSearchFormVersion2 ? 'v2' : 'v1';

  const handleConfirm = () => {
    if (onlyLocations) {
      analytics.gtm.trackConfirmDestination(version);
    } else {
      analytics.gtm.trackConfirmSearchClicked(version);
    }
    sceneProps.onConfirm();
  };

  if (activeScene === 'calendar') {
    return (
      <CalendarScene onClose={unsetActiveScene} onConfirm={unsetActiveScene} />
    );
  }

  if (activeScene === 'pickupLocation') {
    return (
      <SelectStationScene
        onClose={unsetActiveScene}
        onConfirm={unsetActiveScene}
        direction="departure"
      />
    );
  }

  if (activeScene === 'returnLocation') {
    return (
      <SelectStationScene
        onClose={unsetActiveScene}
        onConfirm={unsetActiveScene}
        direction="destination"
      />
    );
  }

  const depDate = new Date(state.depDate);
  const destDate = new Date(state.destDate);
  const invalidTimes = !!state.dateError || destDate <= depDate;

  return (
    <WizardOverlay
      {...sceneProps}
      onConfirm={handleConfirm}
      title={t('headline')}
      buttonText={onlyLocations ? t('summary.station.cta') : t('summary.cta')}
    >
      <ErrorMessages hideDateError />
      <Section>
        <Label>{t('summary.station.pickUp.label')}</Label>
        <Value>
          <IconWrapper>
            <ArrowForward />
          </IconWrapper>
          {state.depLocation?.name ?? ''}
        </Value>
        <EditButton
          onClick={() => {
            analytics.gtm.trackEditDepartureClicked(version);
            setActiveScene('pickupLocation');
          }}
        />
      </Section>
      <Section>
        <Label>{t('summary.station.return.label')}</Label>
        <Value>
          <IconWrapper>
            <ArrowBack />
          </IconWrapper>
          {state.destLocation?.name ?? state.depLocation?.name ?? ''}
        </Value>
        <EditButton
          onClick={() => {
            analytics.gtm.trackEditDestinationClicked(version);
            setActiveScene('returnLocation');
          }}
        />
      </Section>
      {!onlyLocations && (
        <>
          <Section>
            <Label>{t('summary.travelPeriod.label')}</Label>
            <Value>
              {format(new Date(state.depDate), dateLong)}
              {' - '}
              {format(new Date(state.destDate), dateLong)}
            </Value>
            <EditButton
              onClick={() => {
                analytics.gtm.trackEditDatesClicked(version);
                setActiveScene('calendar');
              }}
            />
          </Section>
          <Section multiColumn>
            <Label $isInvalid={invalidTimes}>
              {t('summary.travelPeriod.pickUp.label')}
            </Label>
            <Value>{format(new Date(state.depDate), timeGeneric)}</Value>
            <Label $isInvalid={invalidTimes}>
              {t('summary.travelPeriod.return.label')}
            </Label>
            <Value>{format(new Date(state.destDate), timeGeneric)}</Value>
            <EditButton
              onClick={() => {
                analytics.gtm.trackEditTimeClicked(version);
                setActiveScene('timePicker');
              }}
            />
            {activeScene === 'timePicker' && (
              <TimePickerOverlay
                initialSegment="from"
                from={getTime(depDate)}
                to={getTime(destDate)}
                translations={{
                  title: t('calendar.timePicker.title'),
                  from: t('calendar.timePicker.from'),
                  to: t('calendar.timePicker.to'),
                  hours: t('calendar.timePicker.hours'),
                  minutes: t('calendar.timePicker.minutes'),
                  closeButton: t('calendar.timePicker.closeButton'),
                  confirmButton: t('calendar.timePicker.confirmButton'),
                  // errorMessage: invalidTimes ? t('errors.time') : undefined,
                }}
                onChange={times => {
                  analytics.gtm.trackSelectTime(version);
                  setDate('departure', setTime(depDate, times.from));
                  setDate('destination', setTime(destDate, times.to));
                }}
                onClose={unsetActiveScene}
              />
            )}
            {invalidTimes && (
              <ErrorMessage>{state.dateError || t('errors.time')}</ErrorMessage>
            )}
          </Section>
          <Section>
            <Label>{t('summary.age.label')}</Label>
            <Value>
              <div>{t('summary.age.driverAge', { age: customerAge })}</div>
              <UiLegacy.Tooltip
                content={
                  <UiLegacy.Markup content={t('summary.age.tooltip.text')} />
                }
                trigger={<InfoIcon />}
              />
            </Value>
            <EditButton />
            <AgeDropdown
              value={state.customerAge}
              onClick={() => {
                analytics.gtm.trackEditAgeClicked(version);
              }}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                setCustomerAge(Number(event.target.value));
                analytics.gtm.trackConfirmAge(version);
              }}
            />
          </Section>
        </>
      )}
    </WizardOverlay>
  );
};

export { SummaryScene };
