import { GlobalRating } from '@silvertours/common-landingpages-view';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { Ui } from '@silvertours/front-shared';
import {
  TranslationValues,
  useFormatter,
  useMessages,
  useTranslations,
} from 'next-intl';
import Cancel24H from '@engineering/icons/cancel24h';
import Checked from '@engineering/icons/checked';
import FloytCut from '@engineering/icons/floyt-cut';
import Help from '@engineering/icons/help';
import Like from '@engineering/icons/like';
import MapView from '@engineering/icons/map-view';
import Phone from '@engineering/icons/phone';
import StarEmpty from '@engineering/icons/star-empty';
import World from '@engineering/icons/world';
import { Content } from '@silvertours/common-landingpages-i18n';
import {
  IconWrapper,
  RatingContainer,
  UspContainer,
  UspItem,
  UspItems,
  UspTitleContainer,
  UspTitle,
} from './Benefits.styles';

type USPProps = {
  title?: string;
  rating?: GlobalRating;
  translationKey?:
    | 'usps'
    | 'newsletterUsps'
    | 'rentalCompanyUsps'
    | 'errorUsps';
};

type USPName = 'customerReviews' | 'cancellation' | 'service';

const icons: { [K in UiLegacy.IconNameType]?: React.ComponentType } = {
  Cancel24H,
  Checked,
  FloytCut,
  MapView,
  StarEmpty,
  Help,
  Phone,
  Like,
  World,
};

const Usp: React.FC<USPProps> = ({
  title,
  rating,
  translationKey = 'usps',
}) => {
  const t = useTranslations(`features.benefitsLegacy.${translationKey}`);
  const formatter = useFormatter();
  const messages = useMessages() as unknown as Content;

  const items = Object.keys(
    messages.features.benefitsLegacy[translationKey].content,
  ) as USPName[];

  const makeTranslateKey =
    (item: USPName) => (key: string, values?: TranslationValues) =>
      t(`content.${item}.${key}`, values);

  return (
    <UspContainer>
      {Boolean(title) && (
        <UspTitleContainer>
          <UiLegacy.PrimaryTitle>{title}</UiLegacy.PrimaryTitle>
        </UspTitleContainer>
      )}
      <UspItems>
        {items
          .filter(item => !(item === 'customerReviews' && !rating))
          .map(item => {
            const translateKey = makeTranslateKey(item);

            if (rating && item === 'customerReviews') {
              const reviewsCount = formatter.number(rating.count, {
                useGrouping: true,
              });
              const averageRating = formatter.number(rating.averageRating, {
                maximumFractionDigits: 1,
              });

              const Icon = icons[translateKey('icon')];

              return (
                <UspItem key={item}>
                  {Icon && (
                    <IconWrapper>
                      <Icon />
                    </IconWrapper>
                  )}
                  <div>
                    <UspTitle>{translateKey('title')}</UspTitle>
                    <Ui.TextLink
                      href={`${t('ratingSlug')}.html`}
                      text={translateKey('description', { reviewsCount })}
                    />
                    <RatingContainer>
                      <UiLegacy.Rating
                        value={rating.averageRating}
                        starSize="large"
                      />
                      <UiLegacy.SmallText>
                        <b>{averageRating} / 5</b>
                      </UiLegacy.SmallText>
                    </RatingContainer>
                  </div>
                </UspItem>
              );
            }

            const Icon = icons[translateKey('icon')];

            return (
              <UspItem key={item}>
                {Icon && (
                  <IconWrapper>
                    <Icon />
                  </IconWrapper>
                )}
                <div>
                  <UspTitle>
                    {translateKey('href') ? (
                      <a href={translateKey('href')}>{translateKey('title')}</a>
                    ) : (
                      translateKey('title')
                    )}
                  </UspTitle>
                  {translateKey('description')}
                </div>
              </UspItem>
            );
          })}
      </UspItems>
    </UspContainer>
  );
};

export { Usp };
