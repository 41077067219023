import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ArrowNext from '@engineering/icons/arrow-next';
import { Theme } from '../../../../../Theme';

/**
 * These global styles are required by
 * [`react-day-picker`](https://react-day-picker.js.org/).
 * They are applied to the date picker on both search forms for
 * BM and CG, but also to the widgets under `/widget_search`.
 *
 * @TODO: Consolidate these global styles with the FLOYT date picker
 * styles and move them to the `entities` layer as this is too
 * sophisticated for the `shared` layer.
 */
const GlobalStyles = ({
  theme,
  vertical,
}: {
  theme: Theme;
  vertical: boolean;
}) => css`
  /* stylelint-disable selector-class-pattern */
  .rdp {
    position: relative;
    outline: 0;
    color: ${theme?.color.text};
    background-color: ${theme?.color.white};
    user-select: none;

    ${vertical &&
    css`
      padding-block: ${theme?.spacing[40]};
      background:
        linear-gradient(${theme?.color.white} 30%, rgb(255 255 255 / 0%)),
        linear-gradient(rgb(255 255 255 / 0%), ${theme?.color.white} 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgb(0 0 0 / 20%), transparent),
        radial-gradient(
            farthest-side at 50% 100%,
            rgb(0 0 0 / 20%),
            transparent
          )
          0 100%;
      background-attachment: local, local, scroll, scroll;
      background-repeat: no-repeat;
      background-size:
        100% 40px,
        100% 40px,
        100% 14px,
        100% 14px;
      overflow-y: scroll;
    `}
  }

  .rdp-button_reset {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    background: none;
    appearance: none;
  }

  .rdp-months {
    display: flex;
    flex-direction: ${vertical ? 'column' : ''};
    justify-content: center;
    align-items: ${vertical ? 'center' : ''};
    gap: ${vertical ? '1.5rem' : ''};
  }

  .rdp-month {
    margin: ${vertical ? '' : '1.5rem 1rem 0'};
  }

  .rdp-nav_button {
    position: absolute;
    top: 1.125rem;
    width: 36px;
    height: 36px;
    outline: 0;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      color: #dce0e0;
      cursor: not-allowed;
    }
  }

  .rdp-nav_button.rdp-nav_button_previous {
    left: 0.75rem;
  }

  .rdp-nav_button.rdp-nav_button_next {
    right: 0.75rem;
  }

  .rdp-caption_label {
    margin: 0.25rem 0;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
  }

  .rdp-table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  .rdp-head_cell {
    display: table-cell;
    padding: 0.5rem 0;
    color: #b6bfc1;
    font-size: 0.938rem;
    font-weight: 600;
    text-align: center;
  }

  .rdp-cell {
    padding: 0.5rem 0 0;
  }

  .rdp-day {
    outline: 0;
    width: 32px;
    height: 32px;
    font-size: 1.125rem;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: ${theme?.color.text};
  }

  .rdp-day.rdp-day_outside {
    color: #${theme?.color.primaryHighlight};
    cursor: default;
  }

  .rdp-day.rdp-day_disabled {
    color: #dce0e0;
    cursor: default;
  }

  /* selected pick-up/drop-off dates */
  .rdp-day.rdp-day_selected:not(.rdp-day_disabled, .rdp-day_outside) {
    position: relative;
    color: ${theme?.color.white};
  }

  .rdp-day.rdp-day_range_start.rdp-day_selected:not(
      .rdp-day_disabled,
      .rdp-day_outside
    ) {
    background-image: radial-gradient(
        circle at center,
        ${theme?.color.brand},
        ${theme?.color.brand} 60%,
        transparent 65%
      ),
      linear-gradient(to right, transparent, transparent 49%, #c5e7e2 50%);
  }

  .rdp-day.rdp-day_range_end.rdp-day_selected:not(
      .rdp-day_disabled,
      .rdp-day_outside
    ) {
    background-image: radial-gradient(
        circle at center,
        ${theme?.color.brand},
        ${theme?.color.brand} 60%,
        transparent 65%
      ),
      linear-gradient(to left, transparent, transparent 49%, #c5e7e2 50%);
  }

  .rdp-day.rdp-day_range_start.rdp-day_range_end.rdp-day_selected:not(
      .rdp-day_disabled,
      .rdp-day_outside
    ) {
    background-image: radial-gradient(
      circle at center,
      ${theme?.color.brand},
      ${theme?.color.brand} 62%,
      transparent 63%
    );
  }

  /* range between pick-up and drop-off */
  .rdp-day.rdp-day_selected:not(
      .rdp-day_range_start,
      .rdp-day_range_end,
      .rdp-day_outside
    ) {
    color: ${theme?.color.text};
    background-color: #c5e7e2;
  }

  /* today but not selected */
  .rdp-day.rdp-day_today:not(.rdp-day_selected) {
    margin: 0;
    width: 32px;
    border: 1px solid ${theme?.color.grey01};
    border-radius: 100%;
  }

  .rdp-vhidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  @media screen and (min-width: 300px) {
    .rdp-day {
      width: 40px;
    }

    .rdp-day.rdp-day_today:not(.rdp-day_selected) {
      margin-left: 4px;
      margin-right: 4px;
    }
  }

  /* large widgets only */
  @media screen and ((width: 800px) or (width: 970px)) and (height: 250px) {
    .rdp-month {
      margin-top: 0.125rem;
    }

    .rdp-nav_button {
      top: -0.125rem;
    }

    .rdp-head_cell {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .rdp-cell {
      padding-top: 4px;
    }
  }

  @media screen and (min-width: 1280px) {
    .rdp-months {
      justify-content: space-around;
    }
  }
  /* stylelint-enable selector-class-pattern */
`;

const Wrapper = styled.article`
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  background-color: ${({ theme }) => theme.color.white};
`;

const StyledHeader = styled.header`
  display: flex;
  padding: ${({ theme }) => theme.spacing[40]};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey03};
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
`;

const StyledFooter = styled.footer<{ vertical: boolean }>`
  padding: ${({ theme }) => theme.spacing[40]};
  border-top: ${({ theme, vertical }) =>
    vertical ? `1px solid ${theme.color.grey03}` : ''};
  text-align: ${({ vertical }) => (vertical ? '' : 'right')};
`;

const Arrow = styled(ArrowNext)`
  width: 1rem;
  height: 1rem;
  fill: currentcolor;
`;

export { GlobalStyles, Wrapper, StyledHeader, StyledFooter, Arrow };
