import {
  FaqsContentModule,
  Products,
  PageContent,
} from '@silvertours/common-landingpages-view';

export const useStructuredData = (
  products: Products | null,
  page?: PageContent,
) => {
  if (!page || !('content' in page)) return { stations: [], faqs: undefined };

  const faqs =
    'article' in page.content
      ? page.content.article.segments
          .filter(segment => segment.moduleType === 'faqs')
          .flatMap(segment => (segment as FaqsContentModule).content)
      : undefined;

  const stations =
    'stationsMap' in page.content
      ? page?.content?.stationsMap?.content.stations
      : [];

  const supplierReviews =
    'supplierReviews' in page.content
      ? page?.content?.supplierReviews?.content.supplierReviews
      : [];

  return {
    stations,
    supplierReviews,
    faqs,
    name: products?.name ?? '',
  };
};
