import {
  Runtime,
  useContentModule,
  useSiteInfo,
} from '@silvertours/front-entities';
import { useMedia } from 'react-use';
import { AppPromotion } from './TrustElements/AppPromotion';
import { TrustElement, TrustWrapper } from './TrustSeals.styles';
import { Trustpilot } from './TrustElements/Trustpilot';

const TrustSeals = () => {
  const {
    settings: { featureToggles },
  } = useSiteInfo();
  const { language } = Runtime.useLocale();
  const isLargeViewport = useMedia('(min-width: 992px)', false);
  const { getTrustpilotData } = useContentModule();

  if (!isLargeViewport || !featureToggles.trustElementOnStageImage) {
    return null;
  }

  const trustpilotProps = getTrustpilotData();

  if (language === 'de') {
    return (
      <TrustWrapper>
        <TrustElement isHighlighted>
          <AppPromotion />
        </TrustElement>
        {trustpilotProps && <Trustpilot {...trustpilotProps} />}
      </TrustWrapper>
    );
  }

  if (language === 'fr') {
    return (
      <TrustWrapper>
        <TrustElement isHighlighted>
          <AppPromotion />
        </TrustElement>
        {trustpilotProps && <Trustpilot {...trustpilotProps} />}
      </TrustWrapper>
    );
  }

  return null;
};

export { TrustSeals };
