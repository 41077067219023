import React from 'react';
import { useSiteInfo } from '@silvertours/front-entities';
import { Scene, SceneProps } from './common';
import { CalendarScene } from './CalendarScene';
import { SelectStationScene } from './SelectStationScene';
import { SummaryScene } from './SummaryScene';
import { SearchFormScene } from './SearchFormScene';
import { useSearchContext } from '../SearchFormLegacy/context';

type Props = {
  scenes: Scene[];
  onClose: () => void;
  onConfirm: () => void;
};

const SearchWizard = ({ onClose, onConfirm, scenes }: Props) => {
  const { state } = useSearchContext();

  // If the user has already selected a location, go directly to the final scene
  // as the search form is now in a valid state and a search can be performed
  const initialIndex = state?.depLocation?.cityCode ? scenes.length - 1 : 0;

  const [currentIndex, setCurrentIndex] = React.useState(initialIndex);
  const currentScene = scenes[currentIndex];
  return currentScene.node({
    onClose: () => {
      if (currentScene.goBackOnClose && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      } else {
        onClose();
      }
    },
    onConfirm: () => {
      if (currentIndex === scenes.length - 1) {
        onConfirm();
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    },
  });
};

const useSearchWizard = (): {
  scenes: Scene[];
} => {
  const {
    settings: { featureToggles },
  } = useSiteInfo();

  if (featureToggles.useNewDesignSearchFormVersion2) {
    return {
      scenes: [
        {
          node: (props: SceneProps) => (
            <SelectStationScene {...props} direction="departure" />
          ),
        },
        {
          node: (props: SceneProps) => <SearchFormScene {...props} />,
        },
      ],
    };
  }

  return {
    scenes: [
      {
        node: (props: SceneProps) => (
          <SelectStationScene {...props} direction="departure" />
        ),
      },
      {
        node: (props: SceneProps) => <SummaryScene onlyLocations {...props} />,
      },
      {
        node: (props: SceneProps) => <CalendarScene {...props} />,
      },
      {
        node: (props: SceneProps) => <SummaryScene {...props} />,
      },
    ],
  };
};

export { SearchWizard, useSearchWizard };
