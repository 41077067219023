import { useTranslations } from 'next-intl';
import { ThemeProvider } from '@emotion/react';

import {
  ContentLayout,
  GeneralPageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  TrustLegacy,
  StageLegacy,
  ContentArticle,
  OffersModule,
  SearchFormLegacy,
  SerpOffersModule,
} from '@silvertours/front-features';

import { Theme } from '@silvertours/front-shared';
import { Body } from '../body';

const GeneralContent = ({
  content: {
    article,
    globalRating,
    introduction,
    links,
    location,
    offersByCategory,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<GeneralPageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);
  const offersTranslations = useTranslations('features.offers');
  const { makeSearchHandler } = SearchFormLegacy.useSubmitInitialSearch();

  return (
    <Body
      title={primaryHeadline}
      stage={<StageLegacy.Stage content={stage.content} />}
      content={({ container, containerModule, contentModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
        containerModule(
          'introduction-content',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={introduction?.segments ?? []}
              translations={translations}
            />
          </ThemeProvider>,
          !!introduction?.segments,
        ),
        contentModule(
          'serpOffers',
          <ThemeProvider theme={Theme.defaultTheme}>
            {!!offersByCategory.content && (
              <SerpOffersModule
                content={offersByCategory.content}
                translations={{
                  airConditioning: offersTranslations('car.airConditioning'),
                  carTypes: t.raw('offers.car.type' as any),
                  prevText: offersTranslations('prevText'),
                  nextText: offersTranslations('nextText'),
                  searchButtonText: offersTranslations('searchButtonText'),
                  disclaimertext: offersTranslations('disclaimerSerpOffers'),
                  pricePrefix: offersTranslations('pricePrefix'),
                  priceSuffix: offersTranslations('priceSuffix'),
                  serpTitle: offersTranslations('serp.title'),
                  transmissionAutomatic: offersTranslations(
                    'car.transmissionAutomatic',
                  ),
                  transmissionManual: offersTranslations(
                    'car.transmissionManual',
                  ),
                }}
              />
            )}
          </ThemeProvider>,
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        container('intro', [
          contentModule(
            'offers',
            <ThemeProvider theme={Theme.defaultTheme}>
              {!!offersByCategory.content && (
                <OffersModule
                  content={offersByCategory.content}
                  onClick={makeSearchHandler(stage.content.search?.location)}
                  translations={{
                    badgeText: offersTranslations('badge'),
                    disclaimer: offersTranslations('disclaimerLocation'),
                    disclaimerOverlayCTA: offersTranslations(
                      'disclaimerOverlayCTA',
                    ),
                    durationFrom: offersTranslations('durationFrom'),
                    headline: `${offersTranslations('headlineLocation')} ${
                      location.name
                    }`,
                    pricePrefix: offersTranslations('pricePrefix'),
                    priceSuffix: offersTranslations('priceSuffix'),
                    searchButtonText: offersTranslations('searchButtonText'),
                    offerDetailsTitle: offersTranslations('offerDetailsTitle'),
                    carTypes: t.raw('offers.car.type' as any),
                  }}
                />
              )}
            </ThemeProvider>,
            !!offersByCategory.content &&
              Object.keys(offersByCategory.content).length > 0,
          ),
        ]),
        containerModule('test-winner', <TrustLegacy.CarigamiGarantie />),
        containerModule(
          'article',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={article.segments}
              translations={translations}
            />
          </ThemeProvider>,
        ),
        containerModule(
          'linkLists',
          <ThemeProvider theme={Theme.defaultTheme}>
            <ContentArticle
              modules={links.content ?? []}
              translations={translations}
            />
          </ThemeProvider>,
          !!links.content.length,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { GeneralContent };
