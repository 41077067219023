import { StaticLink } from '@silvertours/common-landingpages-view';
import { ResponsiveLogo } from '@silvertours/front-entities';
import { Theme } from '@silvertours/front-shared';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type {
  ComponentType,
  HTMLAttributes,
  ReactElement,
  SVGProps,
} from 'react';
import { useMedia } from 'react-use';
import { HiddenLinksPopover, MobileMenu, Navigation } from './components';
import {
  Header as StyledHeader,
  IconButtonContainer,
  ActionArea,
  DesktopActions,
  ActionButtons,
} from './Header.styles';
import { useMobileMenus } from './hooks';

export type Props = {
  desktopActions: Array<{
    component: ReactElement;
    hide?: boolean;
    key: string;
  }>;
  mobileMenus: Array<{
    id?: string;
    closeLabel: string;
    hide?: boolean;
    iconLabel: string;
    icon: ComponentType<SVGProps<SVGSVGElement>>;
    content: (closeMenu: () => void) => ReactElement;
    onClose?: () => void;
  }>;
  /** import Header.Navigation for readymade component */
  desktopLinks: StaticLink[];
} & HTMLAttributes<HTMLHeadElement>;

const Header = ({
  children,
  desktopActions,
  desktopLinks,
  mobileMenus,
  ...rest
}: Props) => {
  const { openMenu, setOpenMenu } = useMobileMenus();

  const actionAreaRef = useRef<HTMLDivElement>(null);
  const [menuTop, setMenuTop] = useState<number>(0);

  const positionMenu = useCallback(() => {
    if (actionAreaRef.current) {
      const { bottom } = actionAreaRef.current.getBoundingClientRect();
      if (bottom !== menuTop && bottom > 0) {
        setMenuTop(bottom);
      }
    }
  }, [menuTop]);

  useEffect(() => {
    positionMenu();

    window.addEventListener('resize', positionMenu);
    window.addEventListener('scroll', positionMenu);

    return () => {
      window.removeEventListener('resize', positionMenu);
      window.removeEventListener('scroll', positionMenu);
    };
  }, [actionAreaRef, positionMenu]);

  const [hiddenLinks, setHiddenLinks] = useState<ReactElement[]>([]);

  const getHiddenLinks = (links: ReactElement[]) => {
    setHiddenLinks(links);
  };

  const wideViewPort = useMedia(
    `(min-width:${Theme.getBreakpoint('lg')}px)`,
    true,
  );

  return (
    <>
      {children}
      <StyledHeader {...rest} ref={actionAreaRef}>
        <ResponsiveLogo />
        <ActionArea>
          {wideViewPort ? (
            <DesktopActions>
              <Navigation
                mode="desktop"
                links={desktopLinks}
                getHiddenElements={getHiddenLinks}
              />

              <ActionButtons>
                {desktopActions.map(({ component, key, hide }) =>
                  hide ? null : (
                    <IconButtonContainer key={`actionButton-desktop-${key}`}>
                      {component}
                    </IconButtonContainer>
                  ),
                )}
                {Boolean(hiddenLinks.length) && (
                  <HiddenLinksPopover>{hiddenLinks}</HiddenLinksPopover>
                )}
              </ActionButtons>
            </DesktopActions>
          ) : (
            <ActionButtons id="header-action-buttons">
              {mobileMenus.map(
                (
                  { id, closeLabel, content, hide, icon, iconLabel, onClose },
                  index,
                ) => {
                  const handleMenuClick = () => {
                    setOpenMenu(index);
                  };
                  return hide ? null : (
                    <MobileMenu
                      id={id}
                      closeMenuLabel={closeLabel}
                      renderContent={content}
                      icon={icon}
                      iconLabel={iconLabel}
                      key={`actionButton-${id}-${iconLabel}`}
                      toggleOpen={handleMenuClick}
                      open={openMenu === index}
                      onClose={onClose}
                      topPosition={menuTop}
                    />
                  );
                },
              )}
            </ActionButtons>
          )}
        </ActionArea>
      </StyledHeader>
    </>
  );
};

export { Header };
