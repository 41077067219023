import styled from '@emotion/styled';
import { Theme } from '@silvertours/front-shared';
import { css } from '@emotion/react';

type AffiliateProps = {
  isAffiliate?: boolean;
};

const StageWrapper = styled.div`
  position: relative;
`;

const StageContentContainer = styled.div<AffiliateProps>`
  width: 100%;
  padding: 0;

  ${({ isAffiliate }) =>
    isAffiliate &&
    css`
      display: flex;
      height: 100vh;
      align-items: flex-start;
      justify-content: center;
    `}

  ${Theme.mq('sm')} {
    margin: 0 auto;
    background-color: transparent;
  }

  /* FIXME: This block causes layout issues with larger WidgetSearch, new type of StageContentContainer? */
  ${Theme.mq('lg')} {
    display: flex;
    height: 29.375rem;
    align-items: center;
  }
`;

const StageCaption = styled.div`
  background-color: ${Theme.Color.white};
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 1.875rem 0;

  ${Theme.mq('sm')} {
    width: auto;
  }

  ${Theme.mq('md')} {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    margin-right: 0.625rem;
    padding: 0.625rem 0.9375rem;
  }

  ${Theme.mq('lg')} {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`;

const StageLink = styled.a`
  display: none;

  ${Theme.mq('sm')} {
    display: block;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 29.375rem;
    width: 100vw;
    text-indent: -100em;
  }
`;

const BadgesContainer = styled.aside`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
  display: flex;
  place-items: flex-end;
  gap: 1rem;
  flex-direction: column;
`;

const TrustElement = styled.div`
  display: inline-flex;
  padding: 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background-color: ${({ theme }) => theme.color.white};
`;

export {
  BadgesContainer,
  StageContentContainer,
  StageCaption,
  StageLink,
  StageWrapper,
  TrustElement,
};
