import styled from '@emotion/styled';

const Anchor = styled.a<{ hasText: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: inherit;
  text-decoration: inherit;

  &:focus-visible {
    border-radius: ${({ theme, hasText }) =>
      hasText ? theme.borderRadius.md : '100%'};
    outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
    outline-offset: 0.25rem;
  }
`;

export { Anchor };
